import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Dealers from '../../Componentz/Dealers'


const TenNew = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
	<Helmet>
		<title>10 Marla Plot Payment Plan - Din Gardens Chiniot</title>
		<meta property='og:title' content='10 Marla Plot Payment Plan - Din Gardens Chiniot'/>
		<meta property="og:description" content='10 Marla plot payment plan of Din Gardens, Book your plot with just 20% and remaining payment in monthly easy installment. Visit our site or come to our office to book your plot.' />
		<meta name="description" content='10 Marla plot payment plan of Din Gardens, Book your plot with just 20% and remaining payment in monthly easy installment. Visit our site or come to our office to book your plot.' />
	</Helmet>
    <div class="container">
		<div class="FiveMarlaPP">
			<p>10 MARLA</p>
			
			<p>(MARCH-23 <strong style={{color: "#921A1F"}}> NEW </strong>PAYMENT PLAN)</p>
		</div>
		<div class="container">
      <div className="row mx-auto">
	  <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 ">
				
				<div class="mx-auto">
					{/* <p>&nbsp;</p> */}
					<table class="table table-striped table-hover text-center border border-dark mx-auto" >
					
						<tbody>
							<tr style={{color: "white",backgroundColor: "#165359"}}>
								<th className='text-light'>Months</th>
								<th className='text-light'>Percentage</th>
								<th className='text-light'>Payment Plan</th>
							</tr>
							<tr>
								<td><b>Down Payment</b></td>
								<td className='p-2'>20%</td>
								<td className='p-2'>1,700,000</td>
							</tr>
							{/* <tr>
								<td>1</td>
								<td className='p-2'>0%</td>
								<td className='p-2'>0</td>
							</tr>
							<tr>

								<td>2</td>
								<td className='p-2'>0% </td>
								<td className='p-2'>0 </td>
							</tr>
							<tr>
								<td>3</td>
								<td className='p-2'>0%</td>
								<td className='p-2'>0</td>
								</tr>
							<tr>
								<td>4</td>
								<td className='p-2'>0%</td>
								<td className='p-2'>0</td>
							</tr>
							<tr>
								<td>5</td>
								<td className='p-2'>0%</td>
								<td className='p-2'>0</td>
							</tr> */}
							<tr>
								<td>10-Mar-2023</td>
								<td className='p-2'>1% </td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Apr-2023</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-May-2023</td>
								<td className='p-2'>1% </td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Jun-2023</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Jul-2023</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Aug-2023</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Sep-2023</td>
								<td className='p-2'>5%</td>
								<td className='p-2'>425,000</td>
							</tr>
							<tr>
								<td>10-Oct-2023</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Nov-2023</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Dec-2023</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Jan-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Feb-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Mar-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Apr-2024</td>
								<td className='p-2'>5%</td>
								<td className='p-2'> 425,000</td>
							</tr>
							<tr>
								<td>10-May-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Jun-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Jul-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Aug-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Sep-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Oct-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Nov-2024</td>
								<td className='p-2'>5%</td>
								<td className='p-2'> 425,000</td>
							</tr>
							<tr>
								<td>10-Dec-2024</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Jan-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Feb-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Mar-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Apr-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-May-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Jun-2025</td>
								<td className='p-2'>5%</td>
								<td className='p-2'> 425,000</td>
							</tr><tr>
								<td>10-Jul-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Aug-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Sep-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Oct-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr><tr>
								<td>10-Nov-2025</td>
								<td className='p-2'>1%</td>
								<td className='p-2'> 85,000</td>
							</tr>
							<tr>
								<td>10-Dec-2025</td>
								<td className='p-2'>5%</td>
								<td className='p-2'> 425,000</td>
							</tr>

							<tr>
								<td style={{color: "#fff",backgroundColor: "#165359" }} >Possession Amount</td>
								<td className='p-2'>15%</td>
								<td className='p-2'> 1,275,000-</td>
							</tr>
							<tr>
								<td style={{color: "#fff",backgroundColor: "#165359"}}>Ballot Amount</td>
								<td className='p-2'>11%</td>
								<td className='p-2'>935,000-</td>
							</tr>
							<tr>
								<td ></td>
								<td className='p-2'></td>
								<td className='p-2'  style={{color: "#fff",backgroundColor: "#165359"}}>8,500,000</td>
							</tr>
						</tbody>
					</table>
					<p>&nbsp;</p>
					{/* <p style={{textAlign: "center", fontSize: "20px"}}>Total Installments: 36</p> */}
				</div>
			</div>
      </div>
		
		</div>
		<div class="FiveMarlaPPNotes">
			<h1 style={{fontSize:"22px"}}>Note:</h1>
			<h5>1: Din Gardens will Charge 0.05% per day of total amount due as plenty if customer pays installment after due date which is 10th of every month. Furthermore the file is liable to cancel if thestomer fails to pay installment within 75 days from due date. Cancellation policy will apply.</h5>
			<h5>2: Ballot Amount will be due when balloting is announced by society.</h5>
			<h5>3: Possession Amount will be charged after last installment .</h5>
			<h5>4: All installments will be consider paid only when it will be debited in Din Properties’s bank account.</h5>
		</div>
		<div class="container">
      <div className="row my-3">
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="btn btn-primary FiveMarlaPPBtn m-1" style={{backgroundColor:"#921A1F"}}>
					<Link to="/5marla-payment-plan"className='Link'>5 Marla Payment Plan</Link>
				</div>
			</div>
			{/* <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="btn btn-primary FiveMarlaPPBtn m-1">
					<Link to="/TenNew"className='Link'>10 Marla Payment Plan</Link>
				</div>
			</div> */}
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
				<div class="btn btn-primary FiveMarlaPPBtn m-1" style={{backgroundColor:"#921A1F"}}>
					<Link to="/1kanal-payment-plan"className='Link'>1 Kanal Payment Plan</Link>
				</div>
			</div>
      </div>
		</div>
	</div>
    
    
    </>
  )
}

export default TenNew