import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {BsCheckLg} from "react-icons/bs"
import { Link } from 'react-router-dom'
import Ccarousel from "../Componentz/Ccarousel"
import Images from '../Componentz/EventImages'
import Mosquee from "../Componentz/images/Mosque.jpg"


const Mosque = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
	  const{Mosque}=Images
  return (
    <>
	<Helmet>
		<title>Mosque in Din gardens Chiniot</title>
		<meta property='og:title' content='Mosque in Din gardens Chiniot'/>
		<meta property='og:description'content='Din Gardens aesthetically designed mosque depicts the true spirit of Islamic architecture with space for more than 2000 people. The mosque is one of its kind in its locality.' />
		<meta name='description'content='Din Gardens aesthetically designed mosque depicts the true spirit of Islamic architecture with space for more than 2000 people. The mosque is one of its kind in its locality.' />
	</Helmet>
    <div className="container">
		<div className="row py-2">
		<div className="col-lg-7 col-md-7 col-sm-12">
			<div className="anim-img">
				<Ccarousel Data={Mosque}/>
			</div>
		</div>
		
		<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-center">
		<div id="description" className="">
  				
  				<h6 id="AMh6" style={{color:"#921A1F",fontSize:"20px"}}>Collect some Blessings</h6>
        		<p id="AMcolapsp">The aesthetically designed mosque within Din Gardens Housing Society premises depicts the true spirit of Islamic architecture with space for 2000+ people. This beautiful and mesmerizing mosque is one of its kind in its locality. The indigenous architecture is woven in entirely local materials, reflecting the true essence of Islamic architecture with the sophisticated blend of modern elements.</p>
 			</div>
			<p  className=" bg-warning featuresbtn mx-auto">FEATURES
 			</p>
  			
 			<div id="features" className="">
 				<hr/>
  				<ul className="ulcolapse	">
                    <div className="row">
  					<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Ablution Area-Male & Female</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Car park</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Fully Air Condition</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Imam Guest Room</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Main Prayer Hall</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Toilets/Ablution</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Window Coverings</a></li>
        			</div>
                    </div>
        		</ul>
        	</div>
 		</div>
		 </div>		
		 {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
			<ul className="ULCamera">
				<li title="View Photos"><a id="AMCamera" href="#gallery" data-toggle="tab" aria-expanded="true">
                <i className="fa fa-camera cameraicon"></i></a>
           		</li>
			</ul>
		</div>  */}
     

	</div> 
    
    </>
  )
}

export default Mosque