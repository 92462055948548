import React from 'react'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AiFillPhone } from 'react-icons/ai';
import { MetaTags } from 'react-meta-tags';
import headofmarketing from "../Componentz/images/head-of-marketing.jpg"

const CeoMessage = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Helmet>
      <meta property='og:title' content="CEO - Din Gardens Chiniot"/>
    <title>CEO - Din Gardens Chiniot
</title>
    <meta property='og:description' content='Mr. SM IMRAN is the CEO of Din Gardens Chiniot. Aim of the CEO to provide quality, modern and affordable housing to our respected citizens of Chiniot. This project will set a precedent for modern lifestyle and housing.'/>
    <meta name='description' content='Mr. SM IMRAN is the CEO of Din Gardens Chiniot. Aim of the CEO to provide quality, modern and affordable housing to our respected citizens of Chiniot. This project will set a precedent for modern lifestyle and housing.'/>
    </Helmet>
    <div className='container'>
        <div className='row'>
        <div className="container aboutus-and-ceo">
		<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668777820/Dinproperties/CEO-message_oqvr7c.jpg" alt="ceo-message" title="ceo-message"/>
	</div>
        </div>
        <div id="agents">
			<h5>MEET OUR TEAM</h5>
		</div>
            <div className='row'>
            <div className='col-md-4 mx-auto'>
                <div className='card border-0 mx-auto'>
                    <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668777859/Dinproperties/head-of-marketing_ooe8qe.jpg" alt="hod" title="head of marketing" className='card-img'id="Marketing"/>
                    <h4 className='card-title mt-2'>Asad Shafique</h4>
                    <p>Head of Sales and Marketing</p>
								<p ><a href="tel:+92302-4090200"><AiFillPhone style={{height:"20px",width:"20px"}}/> +92302-4090200</a></p>
                    
                </div>
            </div>
            </div>
    </div>
    


    
    </>
  )
}

export default CeoMessage