import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import DS1 from "../Componentz/images/DS1.jpg"
import DS2 from "../Componentz/images/DS2.jpg"
import DS3 from "../Componentz/images/DS3.jpg"
import ByLawsHeader from "../Componentz/images/ByLawsHeader.jpg"
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';



const ByLaw = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
	<Helmet>
		<meta property='og:title' content='Development Rules & Regulations - Din Gardens Chiniot'/>
		<title>Development Rules & Regulations - Din Gardens Chiniot
</title>
<meta property="og:description" content="	Development rules & regulations for the construction of residential and commercial plots in the Din Gardens housing scheme. Din Gardens is the best society in Chiniot."/>
<meta name="description" content="	Development rules & regulations for the construction of residential and commercial plots in the Din Gardens housing scheme. Din Gardens is the best society in Chiniot."/>
	</Helmet>
		<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668777477/Dinproperties/ByLawsHeader_nl5fuw.jpg" alt="by-law" title="by-law" style={{width: "100%"}}/>
        <div className="LC1 container px-1">
		<h3 className="ByLawH3"><strong>CONSTRUCTION AND DEVELOPMENT REGULATIONS</strong></h3>
		<p className="ByLawP1"><strong>DEFINITIONS</strong></p>
		<p className="ByLawP2">The following nomenclature will have the meanings as a result of this assigned to them respectively or as the context otherwise requires unless there is anything repugnant in the context or subject of these regulations. </p>
	</div>
    <div className="LC2 container px-1">
        <div className='row'>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
			<ul id="regulations">
				<li><strong>DGHS </strong>means Din Gardens Housing Scheme.</li>
				<li><strong>Alternation/Addition of Building: </strong>The physical or structural amendment for making any deletion, addition, or updation in the building.</li>
				<li><strong>Approved Plan </strong>Layout plan or building plan that Din Gardens Housing Scheme approves according to their regulations.</li>
				<li><strong>Area </strong>The area of operation of the Din Gardens Housing Scheme and/is the area shown in the Din Gardens Housing Scheme’s master plan, including any modification or extensions effected therein timely.</li>
				<li><strong>Basement </strong>The lowest story of the building, partially or entirely below ground level. </li>
				<li><strong>Building </strong>Flat, block of flat, house, outhouse, fixed platform, arcade, latrine, plinth, shed, or other roofed structure, whether of brick, metal, mud, wood, brick, masonry, and any part thereof, including a wall (other than a boundary wall not exceeding 7 ft. in height and not abutting on a street), but discluding tent or other temporary/portable shelters that do not have any foundation. </li>
				<li><strong>Building / House Line </strong>A line beyond which the building outface, except the boundary wall, must not be projected in the direction of any proposed or existing street. </li>
				<li><strong>Clear Space/ Open Space </strong>An area forming the plot’s integral part, left open to the sky, including the boundary wall’s thickness.</li>
				<li><strong>Commercial Building </strong>Building or part of building utilized as offices, shops, showrooms, business arcades, display centers, merchandise sale market for retail only. The building is used for business transactions or keeping accounts and records for similar purposes; professional service facilities, restaurants, petrol pumps, banks, theaters, cinemas, and clubs run commercially. Storage and service facilities incidental to the sale of merchandise will be included in this group, excluding where exempted.</li>
				<li><strong>Commercial Zone </strong>Area where commercial buildings are situated, as illustrated in the master plan. </li>
				<li><strong>Master Plan </strong>The plan depicting the area’s layout</li>
				<li><strong>NDC </strong> No Demand Certificate</li>
				<li><strong>NOC </strong> No Objection Certificate.</li>
				<li><strong>Parapet </strong>Wall (whether plain, paneled, perforated, or made of steel angle pipes/irons)  that protects the balcony’s edge, arcade, terrace, or building’s roof. </li>
				<li><strong>Parking Space </strong>Covered or open, unenclosed, or enclosed area sufficient in size to park vehicles in front of public or commercial buildings as specified in the master plan or as recommended by Din Garden Properties.</li>
			</ul>
		</div>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<ul id="regulations">
				<li><strong>Pergola </strong>A structure with a perforated roof consisting of cross bars in the form of reinforced concrete, steel, wood, etc., of which almost 50% of the roof is open to the sky.</li>
				<li><strong>Plinth </strong>Finished floor level of the building.</li>
				<li><strong>Registered Architect/Town Planner: </strong>Engineer:  Qualified Engineer or Architect registered with the respective council (PEC/PCATP).</li>
				<li><strong>Reserved Area </strong>An area depicted in the master plan that was probably re-planned/developed by the DGHS at any stage for any purpose. </li>
				<li><strong>Residential Unit </strong> An independent unit that consists of rooms for human habitation. </li>
				<li><strong>Residential Zone </strong>Zone earmarked for buildings mainly designed for human habitation and in no case will include its use in whole or a part thereof for any other purpose, e.g., schools, institutions, commercial activities, clinics, offices, shops, beauty salons, marriage centers, guest houses, gyms, club activities, tuition centers, workshops, stores or go downs, and more for sectarian, religious and political activities. This also includes gardens, parks, playgrounds, and other open spaces in the area earmarked in the residential building. 
</li>
				<li><strong>Right Of Way (ROW) </strong>The area of road that includes berms/shoulders between two opposite boundary walls of houses’ or shops’ row. </li>
				<li><strong>Septic Tank </strong>A tank collects and decomposes sewage before discharging it into the main or public sewer. </li>
				<li><strong>Shop&nbsp;</strong> Roofed structure primarily used for the retail sale of goods.  </li>
				<li><strong>Arcade </strong>A roofed terrace, gallery, or any other portion of the building with at least one side open to a permanent open space or courtyard. </li>
				<li><strong>Shop / Offices-cum-Flats </strong>Provision of shops on the basement or ground floor with the facility of flats or offices on either the 1st or 2nd floor. </li>
				<li><strong>Stores / Go downs </strong>Building or buildings for material storage or finished goods provided those goods are not of objectionable or inflammable characteristics, and with the provision of office accommodation above but disclude any garage, commercial or residential. </li>
				<li><strong>Sun Rain Shade </strong>An outer side projection from the building over at least 7 ft. height from the plinth level giving protection from weather or harmful sun rays.</li>
				<li><strong>Arcade </strong>A roofed terrace, gallery, or any other portion of the building with at least one side open to a permanent open space or courtyard. </li>
				<li><strong>Zone </strong>The area earmarked for a particular purpose or utilization only. </li>
				
			</ul>
		</div>
        </div>
	</div>
     <div className="LC3 container ">
		<h3><strong>PLOT SIZES</strong></h3>
		<p>Din Gardens Properties maintains plot measurement accuracy allocated to the registered persons. The standard size of residential plots are as follows: </p>
		<table align="center" className='mx-auto'>
			<tbody>
				<tr>
					<th>Sr. No.</th>
					<th>Category of Plot</th>
					<th>Size of Plot</th>
					<th>Area of Plot (Sft)</th>
				</tr>
				<tr>
					<td>1</td>
					<td>1 Kanal</td>
					<td>50’ x 90’</td>
					<td>4500</td>
				</tr>
				<tr>
					<td>2</td>
					<td>10 Marla</td>
					<td>35’ x 65’</td>
					<td>2275</td>
				</tr>
				<tr>
					<td>3</td>
					<td>7 Marla</td>
					<td>25’ x 63’</td>
					<td>1575</td>
				</tr>
				<tr>
					<td>4</td>
					<td>5 Marla</td>
					<td>25’ x 45’</td>
					<td>1125</td>
				</tr>
				<tr>
					<td>5</td>
					<td>4 Marla Com.</td>
					<td>30’ x 30’’</td>
					<td>900</td>
				</tr>
			</tbody>
		</table>
		<p>There can be non-standard plots that are created because of adjustment in town planning area’s re-planning. </p>
	</div> 
    <div className="LC4 container">
		<h3 id="lc4h3-1"><strong>CLEAR SPACES</strong></h3>
		<p>Registered persons have to leave clear spaces for every plot category as follows: </p>
		<h3 id="lc4h3-2"><strong>RESIDENTIAL</strong></h3>
		<table align="center" className='mx-auto'>
			<tbody>
				<tr>
					<th>Plot Size</th>
					<th>Front</th>
					<th>Rear</th>
					<th>Side I</th>
					<th>Side II</th>
					<th>Max. Permissible Cov. Area (GF)</th>
				</tr>
				<tr>
					<td>5 Marla &amp; above but less than 10 Marla</td>
					<td>5 ft</td>
					<td>3 ft</td>
					<td>–</td>
					<td>–</td>
					<td>86% for 5 Marla<br/>
					90% for 7 Marla</td>
				</tr>
				<tr>
					<td>10 Marla &amp; above but less than 15 Marla</td>
					<td>10 ft</td>
					<td>5 ft</td>
					<td>3 ft</td>
					<td>–</td>
					<td>75%</td>
				</tr>
				<tr>
					<td>More than 15 Marla</td>
					<td>15 ft</td>
					<td>5 ft</td>
					<td>5 ft</td>
					<td>3 ft</td>
					<td>66%</td>
				</tr>
			</tbody>
		</table>
		<h3 id="lc4h3-3"><strong>COMMERCIAL</strong></h3>
		<table className="LC4T2 mx-auto"  align="center">
			<tbody align="center" className='mx-auto'>
				<tr>
					<th>Plot Size</th>
					<th>Arcade</th>
					<th>Rear</th>
					<th>Side I</th>
					<th>Side II</th>
					<th></th>
				</tr>
				<tr>
					<td>4 Marla</td>
					<td>5 ft</td>
					<td>5% of the Plot Area</td>
					<td>–</td>
					<td>–</td>
					<td>95%</td>
				</tr>
			</tbody>
		</table>
		<h3 id="lc4h3-4"><strong>FOR ODD SHAPED/NON-STANDARD PLOTS</strong></h3>
		<p>The requirements of clear spaces shall be governed as stated below:</p>
		<ul>
			<li>The open area needed to be left as clear spaces on each side is to be kept clear in totality instead of uniform space from the boundary wall. </li>
			<li>The permissible area to be covered should be built, leaving the rest open and keeping the mandatory front space as standard. </li>
			<li>The permissible covered area proportionate to the plot size is probably covered, leaving the rest as open space on the sides in case of a non-standard plot.</li>
			<li>The particular front clear space should be left on both sides if any member intends to keep the frontage on the longer side in case of a corner plot. </li>
		</ul>
	</div>
    <div className="LC5 container">
		<h3><strong>MAXIMUM HEIGHT</strong></h3>
		<table align="center" className='mx-auto'>
			<tbody align="center">
				<tr>
					<th>Size / Zone</th>
					<th>Max No. of Story</th>
					<th>Max Height</th>
					<th>Minimum Parking Provision</th>
				</tr>
				<tr>
					<td>5 Marla &amp; above but less than 10 Marla</td>
					<td>2+ Mumtee</td>
					<td>36 ft</td>
					<td>1 Car Porch</td>
				</tr>
				<tr>
					<td>10 Marla &amp; Above but less than 1 Kanal</td>
					<td>2+ Mumtee</td>
					<td>36 ft</td>
					<td>1 Car Porch</td>
				</tr>
				<tr>
					<td>1 kanal and above</td>
					<td>2+ Mumtee</td>
					<td>36 ft</td>
					<td>1 Car Porch</td>
				</tr>
			</tbody>
		</table>
		<ul>
			<li>The management of Din Garden Housing Society allow construction of basement to only 1 Kanal or more plots.</li>
			<li>Car porch’s height should not be less than 9’ or more than the roof level of the ground floor if resting on the boundary. </li>
			<li>Commercial zone’s maximum height should not exceed 50’ and 4 floors. </li>
		</ul>
	</div>
	<div className="LC6 container">
		<h3><strong>COVERED AREAS</strong></h3>
		<p>Following points should be considered in the case of residential buildings: </p>
		<ul>
			<li>Basement of the allowable covered area except for the porch area. </li>
			<li>In case construction is envisaged on the 1st floor of 10 Marla and the above-covered area of the 1st floor should not exceed 75% of the total permissible area of the ground floor, irrespective of the area covered at the ground floor. </li>
			<li>2’ wide sun or rain shade should be permitted. However, the shade cannot be combined with an open terrace except on the front side. That shade should not be utilized as a walkway, balcony, or passage. However, brick/concrete parapet wall or railing is permitted. </li>
			<li>Towards front, porch roof shall be projected as cantilever maximum of 2’.</li>
			<li>The maximum number of gates that may rest on the boundary wall: </li>
		</ul>
		<ol>
			<li>1 Kanal - One (Corner plot should have two)</li>
			<li>Less than 1 Kanal - One Porch </li>
		</ol>
		<p>Explanation:  Covering open area and pergola with steel structure, fiberglass, asbestos,   cement sheet, and with other similar material should be taken as a covered area. </p>
		<ul>
			<li>Height of plinth level above ground/road level</li>
		</ul>
		<ol>
			<li>The top of the buildings’ plinth should not be less than 1’-6” from the crown of the adjoining road, in case of building without a basement and 3’-6” in case of building with a basement. </li>
			<li>Lawn level, top of the ramp, and clear spaces should be 1 ft higher than the crown of the adjoining road to have a smooth slope of the ramp. Also, the ramp should be made according to the approved plan of the Din Gardens Properties. </li>
		</ol>
	</div>
	<div className="LC6 container">
        <div className='row mt-1'>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3 className='mt-3'><strong>DISPOSAL OF SURFACE WATER</strong></h3>
			<ul>
				<li>A water channel of size 3” x 6” should be constructed in line with the boundary wall inside the gate. This drain should be suitably disposed of into the house’s manhole. </li>
			</ul>
			<h3><strong>ROOM- SIZE AND HEIGHT</strong></h3>
			<ul>
				<li>Every room should be ventilated. </li>
				<li>The height of the living room’s ceiling in the residential building should not be more than 12’ and less than 9’-6” for the ground and first floors.  </li>
				<li>The height of the basement’s ceiling should not be more than 10’ and less than 9’. </li>
			</ul>
		</div>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3><strong>BOUNDARY WALL</strong></h3>
			<ul>
				<li>Registered person who constructs boundary walls should be liable to contract in his property, and no space from the Right of Way (ROW) of a street or road should be utilized for that purpose. The boundary wall’s maximum height should be 7’ from the adjacent road’s crown. However, a 2’ high steel grill can be erected on the top of the boundary wall to enhance security protocols. </li>
				<li>Din Gardens Properties will check the ground-level boundary wall before starting the main building. </li>
				<li>Corner plot’s house gate will be provided in the chamfered portion of the boundary wall.</li>
			</ul>
		</div>
        </div>
	</div>
	<div className="LC6 container">
        <div className='row'>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3><strong>WATER TANKS</strong></h3>
			<ul>
				<li>The authorized person should construct an overhead and underground water tank in the shop/house. He should arrange to pump water from the underground water tank to the overhead water tank. The Din Gardens Properties will ensure the water reaches the underground tank. </li>
			</ul>
			<h3><strong>GUARD POST</strong></h3>
			<ul>
				<li>A guard post will probably be constructed in the residential building with the size of 5’ x 5’ with a maximum of 8 ft of height from the road level adjoining the main gate towards the lawn. </li>
			</ul>
		</div>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3><strong>BASEMENT OF RESIDENTIAL AREA</strong></h3>
			<ul>
				<li>A single-story basement will be permitted in the residential area. In that case, the height of the ground floor’s plinth will be 3’ - 6” from the crown of the adjoining road. </li>
				<li>The ground floor’s plinth height can be relaxed up to the average plinth level where the sunken area is permitted. </li>
				<li>Sunken area can be extended into clear space, leaving at least a 2’-6” ft wide clear passage. </li>
				<li>Minimum size of 100 sq. feet is compulsory for every residential building to construct a basement. </li>
			</ul>
		</div>
        </div>
	</div>
	<div className="LC6 container">
        <div className='row'>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3><strong>CONSTRUCTION PERIOD</strong></h3>
			<ul>
				<li>The person should commence the building’s construction within 1 year from the date the area has been opened for possession/construction by the authority, failing which the person will be liable to pay a non-construction penalty as per Authority prescription. </li>
				<li>The should complete the building within one and a half years, reckoned from the drawing’s date of approval by the Authority. </li>
				<li>The drawing should remain valid for two years from its approval date. Upon the lapse of two years of validity, the person should be obliged to attain fresh approval. </li>
			</ul>
		</div>
		{/* copy paste */}
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3 className='mt-4'><strong>COMPLETION OF BUILDING</strong></h3>
			<ul>
				<li>On completion of building, the member shall contact Authority by submitting the application for sewer/water connections before occupation of the building.</li>
				<li>Occupation of building without getting the sewer/water connections obtained from the Authority shall be liable to fine.</li>
				<li>Completion certificate shall be issued, if no violation is observed and construction is found in accordance with the approved drawing. Date of sewer opening shall be treated as date of completion of the building.</li>
				<li>Member is  bound  to  obtain  completion  certificate  within  1  year  of approval of drawing, failing which fine shall be levied on monthly basis.</li>
			</ul>
		</div>
        </div>
	</div>
	<div className="LC6 container">
		<h3><strong>CONSTRUCTION VIOLATIONS</strong></h3>
		<ul>
			<li>Ramp slope must finish within 5’ from the boundary wall and drive way shall have a slope conforming to road slope towards the house.</li>
			<li>Permanent fence, rockery and other permanent structure outside the boundary wall shall not be permitted.</li>
			<li>Earth filling outside the boundary wall shall be below the adjoining road edge. DGHS reserves the right to work there whenever the need arises or it wishes to widen the road.</li>
			<li>Temporary guard post including tents if made outside the boundary wall without the permission of DGHS Chiniot.</li>
			<li>Damaging the road by mixing concrete or cutting/bending steel bars on the road, placing concrete mixer on road berm.</li>
			<li>Cutting the road surface without written approval from the DGHS or causing damage to the road or erecting speed breakers or any other obstruction on road shall not be permitted.</li>
		</ul>
	</div>
	<div className="LC6 container">
		<h3><strong>REQUIREMENTS FOR COMMERCIAL ZONE</strong></h3>
		<ul>
			<li>Basement may be constructed under Arcade by leaving space on either side to accommodate underground water tank and septic tank. The level of Arcade shall be kept at the same level as that of existing building on either side, in that row. Only one basement is allowed with maximum depth of 12ft (3.66m) from the road level if the area of plot is up to 1 kanal.</li>
		</ul>
		<p>The person shall ensure the fulfillment of the following standards: –</p>
		<ul>
			<li>Floor level of the shop shall be 1′- 6” above the road level for shops without basements and 3′- 6″ for shops with basement.</li>
			<li>No spouts shall be fixed on the top roof for drainage of water. It shall be drained through down pipes.</li>
			<li>For the first and onward, the person shall make the stairs in his own space, excluding Arcade.</li>
			<li>Joining of two independent buildings/shops through inter connecting Door/opening shall be permitted and will be approved by DGHS.</li>
			<li>2’ projection is allowed towards road side but no construction shall be permitted on the projection.</li>
			<li>Layout of sewerage shall show Gate, screen, manhole, septic tank etc.</li>
			<li>Foundation shall not be permitted to extend beyond the property line.</li>
			<li>No ramp shall be constructed in commercial area but hard standing pavers will be pitched as prescribed pattern with a gradient of 1:100 shall be constructed sloping from road edge to property line, terminating into a masonry drain of approved designed and size, covered with steel grating to receive storm water. The drain would be linked to service sewer. There shall be no separation between adjacent hard standings.</li>
			<li>Obstruction of any nature, such as steps (concrete, steel, wooden) placing of flower pots, erection of any fence/structure and storage of any item in Arcade of any shop/building shall not be permitted. It shall be used as free passage only.</li>
			<li>Machine room for lift is permitted to be constructed on roof top up to 75 sq. feet but within permissible height.</li>
			<li>Staircase (Mumtee) is permitted to be constructed on roof top up to 100 sq. feet but within permissible height.</li>
		</ul>
	</div>
	<div className="LC6 container">
        <div className='row'>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3><strong>DIGGING</strong></h3>
			<ul>
				<li>Nobody shall be permitted to dig or cut the road space including shoulders/berms without prior written permission from the DGHS. Defaulters shall be liable to pay fine as prescribed by the DGHS.</li>
			</ul>
		</div>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3><strong>AVOID BLIND CORNERS</strong></h3>
	        <ul>
				<li>All residential corner plots shall be splayed (champhering of the corner plots should be 5’x5’) to avoid blind corner/ increase sight distance.</li>
			</ul>
		</div>
        </div>
	</div>
	<div className="LC6 container">
        <div className='row'>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3><strong>GROWTH OF TREES AND VEGETATION</strong></h3>
			<ul>
				<li>The occupants or registered persons shall help out the staff of the DGHS in tree plantation and nutriment. Each occupant or registered person shall plant in front of his/her premises at the place marked by the DGHS, whereas all the plantation beyond the boundary wall shall be the property of the DGHS.</li>
				<li>No person shall be allowed to grow any vegetation or to raise obstruction outside his/her premises to encroach/occupy the space or to block the road or to reduce the openness of areas or create obstruction to the visibility i.e. flowers pots/planters and hedges etc. or such like acts or omissions which makes the beautification of the area. To eradicate such acts or omissions, DGHS shall reserve the right to remove such vegetation or obstruction at the cost of such person.</li>
				<li>No person shall be allowed to cut the trees within the area of the DGHS. Whoever violates, shall be fined as prescribed by the DGHS.</li>
			</ul>
		</div>
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			<h3><strong>SEWERAGE / MANHOLE</strong></h3>
	        <ul>
				<li>At the time of completion, DGHS shall provide the facility of sewerage by connecting the house to the main network of sewerage system. The registered person shall pay connection charges as well as monthly sewerage charges as decided by the DGHS from time to time.Registered Person shall construct a septic tank and maintain it effectively so that partially treated sewage flows into the sewerage system.Sewer manhole can be used for drainage of surface water.No person shall be allowed to connect his/her sewer line with the main sewer himself/herself for this he/she has to apply the DGHS, otherwise connection shall be disconnected and &nbsp;he/she shall be liable to pay fine prescribed by the DGHS.</li>
			
            </ul>
		</div>
        </div>
	</div>
	<div className="LC6 container">
		<h3><strong>DRAWINGS AND DOCUMENTS</strong></h3>
		<ul>
			<li>Application for erection, construction, addition or alteration of building shall be made to the DGHS with the following documents:</li>
			<li>6 sets of drawings and one original on tracing cloth.</li>
			<li>Copy of Site Plan showing the north direction, boundaries of proposed plot, adjacent plot, roads and the block in which the plot is located.</li>
			<li>Copy of computerized national identity card (C.N.I.C.) of owner of the plot.</li>
			<li>Set of Ownership documents issued by the society in form Fard, Registry or Allotment Letter, Possession Letter.</li>
			<li>Paid challan for dues.</li>
			<li>Undertakins required under these Regulations.</li>
			<li>One copy of NDC (No Demand Certificate).</li>
			<li>Form A&amp;B from Architect, Registered with PCATP (for TMA)</li>
			<li>Structural Stability Certificate from Structure Engineer registered with PEC</li>
			<li>The drawing attached includes:</li>
			<li>All internal and external dimensions including spaces.</li>
			<li>Schedule of open and covered areas.</li>
			<li>Location plan indicating surroundings of the property.</li>
			<li>Cardinal North sign.</li>
			<li>Purpose and use of the property.</li>
			<li>Ramp details as given by the society.</li>
			<li>Foundation details as given by society or approved architect/Engineer.</li>
			<li>Total Height of the building.</li>
			<li>Details of boundary walls towards road.</li>
			<li>Details of all levels from Road, Porch, Finished Floor levels and Plinth levels.</li>
			<li>Schedule of all doors and windows.</li>
			<li>Details of all services (UGWT, SPT, OHWT) plan on location plan and disposal to main lines.</li>
			<li>Plan and elevation on scale 1”=8’</li>
			<li>Registered Person shall construct a septic tank and maintain it effectively so that partially treated sewage flows into the sewerage system.</li>
		</ul>
		<p>Sewer manhole can be used for drainage of surface water.</p>
		<p>No person shall be allowed to connect his/her sewer line with the main sewer himself/herself for this he/she has to apply the DGHS, otherwise connection shall be disconnected and &nbsp;he/she shall be liable to pay fine prescribed by the DGHS.</p>
	</div>
	<div className="LC6 container">
		<h3><strong>DISCLAIMER</strong></h3>
		<ul>
			<li>The property owner will be wholly solely responsible for any mishap due to faulty construction of their building and in no way DGHS will be responsible for such an act.</li>
		</ul>
	</div>
	<div className="LC6 container">
		<h3><strong>GENERAL</strong></h3>
		<ul>
			<li>There is positive evidence of the presence of white ants in the area of the DGHS; Registered Persons are advised to carry out termite proofing.</li>
			<li>Green belts shall always be kept lower than the road berm, failing which it shall be dozed off by the DGHS without giving any notice.</li>
			<li>Vacant plots shall not be used for marriage, parties and meetings etc. without written permission from the DGHS and on payment of the prescribed charges. Washing of cars outside the house is not permitted.</li>
			<li>Garbage shall not be thrown in front of houses/shops/public buildings. Garbage shall be placed in basket, which shall be fixed on the boundary wall of the houses from where DGHS staff shall pick up the garbage for disposal. The DGHS shall provide basket on payment. Shopkeepers shall place dustbin in front of their shops and place the garbage in dustbin from where it shall be picked up under DGHS arrangements.</li>
			<li>In order to avoid inconvenience to the customers, air conditioners shall be installed at the height of 8’ from the level of Arcade and proper arrangements shall be made for drainage of water. Preferably split type of air conditions may be installed.</li>
			<li>Wall chalking, writing on walls and pasting of posters etc. on the walls shall not be allowed.</li>
		</ul>
	</div>
	<div className="container">
		<p >
		<strong>Note:</strong>
		The DGHS can take any action, deemed fit and proper, in respect of any violation done by anyone. Therefore, no violation done by a person would be treated as precedent for the other. These bye laws are for the comfortable and secure living of residents and property bearer living with in DGHS.</p>
	</div>
	<div className="DS container">
		<p><strong>DRAWINGS SAMPLE</strong>
		</p>
	</div>
    <div className='DSS container'>
        <div className='row  mt-5 mx-auto'>
            <div className='col-md-5 mx-auto '>
    <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100 h-50"
                        src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668777589/Dinproperties/DS1_yva36d.jpg"
                        alt="map1"
						title="map1"
                    />
                   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 h-50"
                        src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668777590/Dinproperties/DS6_xrviwd.jpg"
                        alt="map2"
						title="map2"
                    />

                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 h-50"
                        src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668777590/Dinproperties/DS5_sst0p9.jpg"
                        alt="map3"
						title="map3"
                    />

                    
                </Carousel.Item>
            </Carousel>
            </div>
            </div>
    </div>
	
    </>
  )
}

export default ByLaw