import React,{useEffect} from 'react'
import Topbar from '../Components/Topbar';
import Navbar from '../Componentz/Navbar';
import Footer from '../Componentz/Footer';
import MainPage from './MainPage';
import { Route, Routes } from 'react-router-dom';
import RithIcon from '../Components/RithIcon';
import Aboutus from './Aboutus';
import CeoMessage from './CeoMessage';
import ByLaw from './ByLaw';
import ContactUS from './ContactUS';
import Dealers from './Dealers';
import TVC from './TVC';
import Events from './Events';
import Mosque from './Mosque';
import Community from './Community';
import School from './School';
import Parks from './Parks';
import Hospital from './Hospital';
import SuperMarket from './SuperMarket';
import Residential from './Residential';
import Commercial from './Commercial';
import FivePayment from './Marlas/FivePayment';
import Fivemid from './Marlas/Fivemid';
import SevenOld from './Marlas/SevenOld';
import SevenNew from './Marlas/SevenNew';
import TenOld from './Marlas/TenOld';
import TenNew from './Marlas/TenNew';
import TenFive from './Marlas/TenFive';
import TenFour from './Marlas/TenFour';
import TenThree from './Marlas/TenThree';
import Kanal from './Marlas/Kanal';
import KanalNew from './Marlas/KanalNew';
import ComOne from './Marlas/ComOne';
import ComTwo from './Marlas/ComTwo';
import ComThree from './Marlas/ComThree';
import ComFour from './Marlas/ComFour';
import Amenities from './Marlas/Amenities';
import Terms from './Terms';
import Privacy from './Privacy';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Block from './Block';
import News from '../Componentz/News';
import Press from './Press';
import Commercialpaymentplan from './Marlas/Commercialpaymentplan';



const Index = () => {
  
  // useEffect(() => {
  //   window.scrollTo(0, 0)
    
  // }, [])
 
   
  return (
    <>
    
    <Topbar/>
    <Navbar/>
    <News/>
    
    
    <Routes>
      <Route element={<MainPage/>} path="/"/>
      <Route element={<Aboutus/>} path="/about-us"/>
      <Route element={<CeoMessage/>} path="/ceo"/>
      <Route element={<ByLaw/>} path="/by-law"/>
      <Route element={<ContactUS/>} path="/contact-us"/>
      <Route element={<Dealers/>} path="/dealers"/>
      <Route element={<TVC/>} path="/tvc"/>
      <Route element={<Events/>} path="/events"/>
      <Route element={<Mosque/>} path="/mosque"/>
      <Route element={<Community/>} path="/community-center"/>
      <Route element={<School/>} path="/school"/>
      <Route element={<Parks/>} path="/parks"/>
      <Route element={<Hospital/>} path="/hospital"/>
      <Route element={<SuperMarket/>} path="/supermarkets"/>
      <Route element={<Residential/>} path="/residential"/>
      <Route element={<Commercial/>} path="/commercial"/>
      <Route element={<FivePayment/>} path="/5marla-payment-plan"/>
      <Route element={<FivePayment/>} path="/residential/5marla-payment-plan"/>
      <Route element={<TenNew/>} path="/residential/10marla-payment-plan"/>
      <Route element={<KanalNew/>} path="/residential/1kanal-payment-plan"/>
      <Route element={<Commercialpaymentplan/>} path="/commercial/commercial-payment-plan"/>

      <Route element={<Fivemid/>} path="/fiveMid"/>
      <Route element={<SevenOld/>} path="/sevenOld"/>
      <Route element={<SevenNew/>} path="/sevenNew"/>
      <Route element={<TenOld/>} path="/tenOld"/>
      <Route element={<TenNew/>} path="/10marla-payment-plan"/>
      <Route element={<TenThree/>} path="/tenThree"/>
      <Route element={<TenFour/>} path="/tenFour"/>
      <Route element={<TenFive/>} path="/tenFive"/>
      <Route element={<Kanal/>} path="/kanal"/>
      <Route element={<KanalNew/>} path="/1kanal-payment-plan"/>
      <Route element={<Commercialpaymentplan/>} path="/commercial-payment-plan"/>
      <Route element={<ComOne/>} path="/com1"/>
      <Route element={<ComTwo/>} path="/com2"/>
      <Route element={<ComThree/>} path="/com3"/>
      <Route element={<ComFour/>} path="/com4"/>
      <Route element={<Amenities/>} path="/Amaneties"/>
      <Route element={<Terms/>} path="/terms"/>
      <Route element={<Privacy/>} path="/privacy"/>
      <Route element={<Block/>} path="/blog"/>
      <Route element={<Press/>} path="/press-release"/>



    </Routes>
    <RithIcon/>
    <Footer/>
    

    </>
  )
}

export default Index