import React from 'react'
import { useEffect } from 'react'
import {BsCheckLg} from "react-icons/bs"
import { Link } from 'react-router-dom'
import Images from '../Componentz/EventImages'
import Ccarousel from '../Componentz/Ccarousel'
import AMpark from "../Componentz/images/AMthemepark.jpg"
import { Helmet } from 'react-helmet'




const Parks = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
	  const{Park}=Images
  return (
    <>
	<Helmet>
		<title>Parks in Din Gardens Chiniot</title>
<meta property='og:title' content='Parks in Din Gardens Chiniot'/>
<meta property='og:description'content='Din Gardens offers some of the best parks in Chiniot city, with jogging tracks and play areas. Visit society and book your plots in Din Gardens Chiniot.' />
<meta name='description'content='Din Gardens offers some of the best parks in Chiniot city, with jogging tracks and play areas. Visit society and book your plots in Din Gardens Chiniot.' />
	</Helmet>
    <div className="container">
		<div className="row py-2">
		<div className=" col-lg-7 col-md-7col-sm-12">
			<div className="anim-img">
				<Ccarousel Data={Park}/>
			</div>
		</div>
		
		<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-center">
			
  			<div id="description" className="">
  	
  				<h6 id="AMh6" style={{color:"#921A1F",fontSize:"20px"}}>Let’s Grow Old and Stay Young Together</h6>
        		<p id="AMcolapsp" className='text-justify'>Giggles, laughter, recalls, and shouts reverb through the walls of your humble abode and warm your heart, revive your love, rejuvenate your desire to thrive, and strengthen your belief in life. Your infants and short lings play and grow with a dream to be an influencing personality. </p>
 			</div>
			 <p className="bg-warning featuresbtn mx-auto">FEATURES
 			</p>
 			<div id="features" className="">
 				<hr/>
  				<ul className="ulcolapse">
                    <div className="row">
  					<div className="col-sm-6">
  						<li><a href=""><BsCheckLg/>Cafeteria</a></li>
        			</div>
					<div className="col-sm-6">
        				<li><a href=""><BsCheckLg/>First Aid Centre</a></li>
        			</div>
        			<div className="col-sm-6">
        				<li><a href=""><BsCheckLg/>Car park</a></li>
        			</div>
        			<div className="col-sm-6">
        				<li><a href=""><BsCheckLg/>Rides and Games</a></li>
        			</div>
                    </div>
        		</ul>
        	</div>
 		</div>
		 </div>		
		
	</div>
    </>
  )
}

export default Parks