
import leatherproducts from "../Componentz/images/leather-products.jpg"
import textilemills from "../Componentz/images/textile-mills.jpg"
import dairyproducts from "../Componentz/images/dairy-products.jpg"
import energy from "../Componentz/images/energy.jpg"
import BTW from "../Componentz/images/BTW.jpg"
import weplay from "../Componentz/images/we-play.jpg"
import womencollage from "../Componentz/images/women-collage.jpg"
import dinproperties from "../Componentz/images/din-properties.jpg"
import dingardens from "../Componentz/images/din-gardens.jpg"

const Data=[
    {
        img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775506/Dinproperties/leather-products_ssnk1h.jpg",
        title:"DIN LEATHER",
        text:"Located in one of the largest industrial areas of Pakistan, S.I.T.E, Din Leather (Pvt) Ltd, is one of the largest tanneries of the country, exporting finished goat leather across the globe, specializing in high end leather."
    },
    {
        img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775534/Dinproperties/textile-mills_qh9nb4.jpg",
        title:"DIN textile MILLS",
        text:"From the day of inception, Din Abouttextile has been constantly striving to achieve excellence and generate highest value for all of its stakeholders. Today Din Abouttextile holds an unchallenged position at forefront of industry within the country. "
    },
    {
        img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775576/Dinproperties/dairy-products_hlxwbk.jpg",
        title:"DIN FARM PRODUCTS",
        text:"Din Farm Products (Pvt.) Ltd is a company of din Industries, Din Farm is the First Chicken Layer Farm in Pakistan accredited with ISO 22000:2005(Food Safety Management System) about 300,000 farm fresh eggs are produced here in one day."
    },
    {
        img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775606/Dinproperties/energy_rycay1.jpg",
        title:"DIN ENERGY",
        text:"Din Energy Ltd is developing a 50 MW WPP with the cost of Rs. 12 Billion, has obtained all major approvals & pursuing for tariff. DEL has negotiated with local & foreign financiers and major EPC contractors. Financial Close expected in June-2018."
    },
    {
        img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775635/Dinproperties/BTW_hdgudn.jpg",
        title:"BY THE WAY",
        text:"BTW is the clothing brand of Din Industries that incorporates the perfect mix of fun, youthful, trendy and affordable clothing for all occasions, BTW introduces the first of its kind clothing to stylize the fashion industry with joyful and eye-catching clothing."
    },
    {
        img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775668/Dinproperties/we-play_n45dfh.jpg",
        title:"WE PLAY",
        text:"We play is another fun paradise under the banner of Vibractive. It is designed to be Pakistan’s favorite entertainment center. It offers an amalgamation of thrill, fun entertainment and happiness."
    },
    {
        img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775696/Dinproperties/women-collage_jihfsx.jpg",
        title:"DIN COLLEGE FOR WOMEN",
        text:"o provide high quality education to the daughters of Chiniot, Din College for women Chiniot has been established under the auspices of Chiniot Anjuman Islamia and Din Industries."
    },
    {
        img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775732/Dinproperties/din-properties_viitgc.jpg",
        title:"DIN PROPERTIES",
        text:"Din Properties is a recent venture of Din Industries aimed at providing quality and affordable housing to our customers. with years of service for the people of Chiniot. Din Properties is a name that you can trust."
    },
    {
        img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775764/Dinproperties/din-gardens_prvc1r.jpg",
        title:"DIN GARDENS",
        text:"Din Gardens is the flagship project of Din Properties. Din Gardens will be the first of its kind housing society strategically located in the heart of chiniot just 3 km from main Kalma Chowk on Faislabad Sargodha Road."
    },
    
]
export default Data