import React,{useState} from 'react'
import  emailjs  from 'emailjs-com'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      
    },
    
  };


const CContactUS = () => {
   
    
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [company, setCompany] = useState("")
    const [Subject, setSubject] = useState("")
    const [Message, setMessage] = useState("")
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [Meessage,setMeessage]=useState("Message has been send successfully")

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }



    const Sending=()=>{
        toast.warning("wait while your message is deliverd !", {
            position: toast.POSITION.TOP_CENTER
          });
    }

    function contactusApi (e) {
        openModal();
        
        axios.post(`https://contactapi.softvalley.com.pk/api/Contactus`,
            {
                "Firstname": firstname,
                "Lastname": lastname,
                "Email": email,
                "Mobile": mobile,
                "Company": company,
                "Subject": Subject,
                "Message": Message
            })
            .then((respon) => {
                if (respon.data.Message === "Success") {
                    setMeessage("Message has been send successfully")

                        setTimeout(()=>{
                            setMeessage("Wait email is sending....")
                            closeModal();


                        },2000)
                    // toast.success("Message has been sent successfully !", {
                    //     position: toast.POSITION.TOP_CENTER
                    //   });
              
                   
                    setFirstname("")

                    setEmail("")
                    setMobile("")
                   
                    setSubject("")
                    setMessage("")

                }
                else {
                    toast.error("something create prpblem please wait !", {
                        position: toast.POSITION.TOP_CENTER
                      });
                    
                }
            }).catch((er) => {
                console.log('er', er)
            })
    }
   
    // const sendMail=(e)=>{
        
    //     emailjs.sendForm('service_b09d0w7','template_hl1ow94',e.target,'qmsDpMytQodoIYGDF').then(res=>{console.log(res)}).catch(err=>{console.log(err)})
    // }
  return (
    <>
    <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
   <div  id="form_container">
                    <h1 className="text-center" style={{color:"#921a1f",fontWeight:"bold"}}>We Love To Hear From You</h1> 
                    <p className="text-center" style={{color:"#000000",fontWeight:"bold"}} > Please send your message below. We will get back to you at the earliest! </p>
                    <form role="form"  id="reused_form" onSubmit={contactusApi}  >
                        <div className="row">
                            <div className="col-sm-6 form-group">
                                <label for="name"> Your Name:</label>
                                <input type="text" className="form-control" id="name" name="name" value={firstname} onChange={e => setFirstname(e.target.value)} required/>
                            </div>
                            <div className="col-sm-6 form-group">
                                <label for="Phone"> Phone:</label>
                                <input type="tel" className="form-control" id="email" name="mobile" pattern="[0-9]{11}"  value={mobile} onChange={e => setMobile(e.target.value)} required/>
                            </div>
                            <div className="col-sm-12 form-group">
                                <label for="email"> Email:</label>
                                <input type="email" className="form-control" id="email" name="email"  value={email} onChange={e => setEmail(e.target.value)} required/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 form-group">
                                <label for="message"> Message:</label>
                                <textarea className="form-control" type="textarea" name="message" id="message"  value={Message} onChange={e => setMessage(e.target.value)} maxlength="6000" style={{height:"100px"}}></textarea>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-sm-12 form-group mt-1">
                                <button className="btn btn-lg btn-primary"type='submit' style={{backgroundColor:"#921a1f",color:"#ffffff"}} >Send &rarr;</button>
                            </div>
                        </div>
                    </form >
                </div>


                <div>
      
      <Modal
                 className="Modal w-75 "
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"

      >
        <h2>
            {Meessage}
             </h2>
        
      </Modal>
    </div>



    </>
  )
}

export default CContactUS