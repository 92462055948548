import React from 'react';
import './App.css';
import HomePage from './Pages/HomePage';
import RithIcon from './Components/RithIcon';
import Index from './Pages/Index';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.min.js"
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Modal from "react-modal"
import { hydrate, render } from "react-dom";


const App = () => {

  // setTimeout(() => {
  //   document.getElementById("modale").click();

  // }, 4000)
  return (
    <>
      <BrowserRouter>

        <Index />
        <RithIcon />
      </BrowserRouter>
    </>
  );

}


export default App;
