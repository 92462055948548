import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {BsCheckLg} from "react-icons/bs"
import { Link } from 'react-router-dom'
import market from "../Componentz/images/AMmarket.jpg"



const SuperMarket = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
	<Helmet>
		<title>Supermarkets in Din Gardens Chiniot</title>
<meta name='description' content='Supermarkets in Din Gardens Chiniot - Supermarkets serving customers in Chiniot with extensive range of products covering almost the entire range of Grocery, Health & Beauty, Skin Care, Household grocery, Crockery, Home electronics, Plastic ware and much more.' />
<meta property='og:description' content='Supermarkets in Din Gardens Chiniot - Supermarkets serving customers in Chiniot with extensive range of products covering almost the entire range of Grocery, Health & Beauty, Skin Care, Household grocery, Crockery, Home electronics, Plastic ware and much more.' />
	<meta property='og:title' content='Supermarkets in Din Gardens Chiniot'/>
	</Helmet>
    <div className="container">
		<div className="row my-2">
		<div className="col-lg-7 col-md-7 col-sm-12">
			<div className="anim-img">
				<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776416/Dinproperties/AMmarket_ejjwyp.jpg" alt="market" title="supermarket"/>
			</div>
		</div>
		
		<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-center">
		<div id="description" className="">
  				
  				<h2 id="AMh6" style={{color:"#921A1F",fontSize:"20px"}}>Get your hands on high-quality, affordable items! </h2>
        		<p id="AMcolapsp">Din Gardens Properties at Chiniot aims to offer an extensive range of products covering almost the entire range of grocery, household products, cosmetics, skincare, home electronics, crockery, plastic ware, and much more. Step in and get your favorite products at the best affordable prices in the approaching years.</p>
 			</div>
			<p  className="bg-warning featuresbtn text-center mx-auto " >FEATURES
 			</p>
  			
 			<div id="features">
 				<hr/>
  				<ul className="ulcolapse">
                    <div className="row">
        			<div className="col-sm-6">
        				<li><a href=""><BsCheckLg/>Car park</a></li>
        			</div>
        			<div className="col-sm-6">
        				<li><a href=""><BsCheckLg/>Fully Air Condition</a></li>
        			</div>
        			<div className="col-sm-6">
        				<li><a href=""><BsCheckLg/>Main Prayer Hall</a></li>
        			</div>
        			<div className="col-sm-6">
        				<li><a href=""><BsCheckLg/>Toilets/Ablution</a></li>
        			</div>
                    </div>
        		</ul>
        	</div>
 		</div>
		 </div>		
	
	</div>
    
    </>
  )
}

export default SuperMarket