import React from 'react';
import Carousel from 'react-bootstrap/Carousel';


const Slider = (props) => {
    const {Slider1,Slider2,Slider3,control,indicator}=props
    return (
        <>
            <Carousel controls={control} indicators={indicator} >
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Slider1}
                        alt="Din Gardens Chiniot"
                    />
                   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Slider2}
                        alt="Din Gardens Chiniot
                        "
                    />

                    
                </Carousel.Item>
             
            </Carousel>
        </>
    );
}
export default Slider;