import React, { useEffect, useState } from 'react';
import { FaFacebook, FaYoutube  } from 'react-icons/fa';
import { AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';
import { IoMdCall  } from 'react-icons/io';
import { MdOutgoingMail  } from 'react-icons/md';
import "../Componentz/Style.css"
import { Link } from 'react-router-dom';

const Topbar = () => {

    const [openmoda,SetmodalOpe]=useState('')


    function modalOnOff(){
      SetmodalOpe("none")
    
    }

    useEffect(()=>{

        SetmodalOpe("flex")
    },[])

    return (
        <>
        
       <div style={{position:"relative",overflow:"hidden"}}> </div>
        <div className='modalmain' style={{ display:openmoda}}>
    <div className='modalcrosDivup' style={{display:openmoda}}>
    <div className='modaldiv' >
  <a type="button" id='modal1' className='modalcross' onClick={modalOnOff}  >X</a>


  </div>
<div className='modalImagediv' >

<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391409/dinproperties2023/din-properties-website_hjhadx.webp" className='webopen '/>

</div>
    </div>
    </div> 
            <div style={{backgroundColor:"#921a1f"}} class="topbar p-1 text-white">
                <div className='container'>
                    <div className=''>
                        <ul className='nav navbar-nav navbar-right d-flex flex-row-reverse gap-5'>
                            <li  className='d-flex flex-row gap-3 justify-content-center align-items-center'>
                                <a className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center' href='https://www.facebook.com/DinGardensPk/'>
                                    <FaFacebook/>
                                </a>
                                <a className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center' href='https://twitter.com/DinGardens'>
                                    <AiFillTwitterCircle/>
                                </a>
                                <a className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center' href='https://www.instagram.com/dingardens/'>
                                    <AiFillInstagram/>
                                </a>
                                <a className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center' href='https://www.youtube.com/channel/UCCZPhVuHxv_ADrXroYrHPKA/videos'>
                                    <FaYoutube/>
                                </a>
                                
                            </li>
                            <li >
                                <a className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center gap-1' href='tel:042-111-111346'>
                                   <IoMdCall size='16px'/> 042-111-111-346
                                </a>
                            </li>
                            <li className=''>
                                <Link className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center gap-1' to='/contact-us'>
                                  <MdOutgoingMail size='18px' />  info@dinproperties.com.pk
                                </Link>
                            </li>

                        </ul>

                    </div>

                </div>
            </div>
        </>
    );
}

export default Topbar;