import React from 'react'
import Dealers from '../../Componentz/Dealers'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'


const SevenNew = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
     <div class="container">
		<div class="FiveMarlaPP">
			<p>7 MARLA</p>
			<p style={{color: "green"}}>NEW July(5Lac)</p>
			<p>(01-AUGUST-19 PAYMENT PLAN)</p>
		</div>
		<div class="container">
      <div className="row">
			<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5 pb-5">
				<div class="FiveMarlaPPOH">
					<p>7 MARLA</p>
					<p>(01-AUGUST-19 MONTHLY PAYMENT PLAN)</p>
				</div>
				<div class="FMFTable mb-5">
					<p>&nbsp;</p>
					<table class="FiveMarlaPPTable text-center m-auto">
						<tbody>
            <tr id="FiveMarlaTr1">
								<th>7 Marla Payment Plan</th>
								<th>Monthly</th>
							</tr>
							<tr>
								<td>Down Payment</td>
								<td className='p-2'>700,000/-</td>
							</tr>
							<tr>
								<td>15 June, 18&nbsp; -To-&nbsp; 10 Nov, 18</td>
								<td className='p-2'>1% (35,000)</td>
							</tr>
							<tr>
								<td>10-Dec-2018</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10 Jan, 19&nbsp; -To-&nbsp; 10 Jun, 19</td>
								<td className='p-2'>1% (35,000)</td>
								</tr>
							<tr>
								<td>10-Jul-2019</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10 Aug, 19&nbsp; -To- 10 Jan, 20</td>
								<td className='p-2'>1% (35,000)</td>
							</tr>
							<tr>
								<td>10-Feb-20</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10 Mar, 20 -To-&nbsp; 10 Aug, 20</td>
								<td className='p-2'>1% (35,000)</td>
							</tr>
							<tr>
								<td>10-Sep-2020</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10 Oct, 20 -To-&nbsp; 10 Feb, 21</td>
								<td className='p-2'>1% (35,000)</td>
							</tr>
							<tr>
								<td>10-Mar-2021</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10-Apr-2021</td>
								<td className='p-2'>15% (525,000)</td>
							</tr>
							<tr>
								<td style={{color: "#fff", backgroundColor: "#165359"}}>Ballot Amount</td>
								<td className='p-2'>11% (385,000)-</td>
							</tr>
						</tbody>
					</table>
					<p>&nbsp;</p>
					<p style={{textAlign: "center", fontSize: "20px"}}>Total Installments: 36</p>
				</div>
			</div>
			<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
				<div class="FiveMarlaPPOH">
					<p  style={{color: "#921a1f"}}>7 MARLA</p>
					<p  style={{color: "#921a1f"}}>(15 MARCH-18 QUARTERLY PAYMENT PLAN)</p>
				</div>
				<div class="FMFTable">
					<p>&nbsp;</p>
					<table class="FiveMarlaPPTable2 text-center m-auto" align="center">
						<tbody>
							<tr style={{border:" 2px solid #921a1f", textAlign: "center", color: "#921a1f", height: "30px"}}>
								<th>7 Marla Payment Plan</th>
								<th>Quaterly</th>
							</tr>
							<tr>
								<td>Down Payment</td>
								<td className='p-2'>700,000/-</td>
							</tr>
							<tr>
								<td>15-June-2018</td>
								<td className='p-2'>3% (105,000)</td>
							</tr>
							<tr>
								<td>10-Sep-2018</td>
								<td className='p-2'>3% (105,000)</td>
							</tr>
							<tr>
								<td>10-Dec-2018</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10-Jan-2019</td>
								<td className='p-2'>3% (105,000)</td>
							</tr>
							<tr>
								<td>10-Apr-2019</td>
								<td className='p-2'>3% (105,000)</td>
							</tr>
							<tr>
								<td>10-Jul-2019</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10-Aug-2019</td>
								<td className='p-2'>3% (105,000)</td>
							</tr>
							<tr>
								<td>10-Nov-2019</td>
								<td className='p-2'>3% (105,000)</td>
							</tr>
							<tr>
								<td>10-Feb-2020</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10-Mar-2020</td>
								<td className='p-2'>3% (105,000)</td>
							</tr>
							<tr>
								<td>10-Jun-2020</td>
								<td className='p-2'>3% (105,000)</td>
							</tr>
							<tr>
								<td>10-Sep-2020</td>
								<td className='p-2'>3% (105,000)</td>
							</tr>
							<tr>
								<td>10-Oct-2020</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10-Jan-2021</td>
								<td className='p-2'>2% (70,000)</td>
							</tr>
							<tr>
								<td>10-Mar-2021</td>
								<td className='p-2'>5% (175,000)</td>
							</tr>
							<tr>
								<td>10-Apr-2021</td>
								<td className='p-2'>15% (525,000)</td>
							</tr>
							<tr>
								<td style={{color: "#fff", backgroundColor: "#921a1f"}}>Ballot Amount</td>
								<td className='p-2'>11% (385,000)-</td>
							</tr>
						</tbody>
					</table>
					<p>&nbsp;</p>
					<p style={{textAlign: "center", fontSize: "20px"}}>Total Installments:17</p>
				</div>
			</div>
      </div>
		</div>
		<div class="FiveMarlaPPNotes">
			<h1 style={{fontSize:"22px"}}>Note:</h1>
			<h5>1: Din Gardens will Charge 0.05% per day of total amount due as plenty if customer pays installment after due date which is 10th of every month. Furthermore the file is liable to cancel if thestomer fails to pay installment within 75 days from due date. Cancellation policy will apply.</h5>
			<h5>2: Ballot Amount will be due when balloting is announced by society.</h5>
			<h5>3: Possession Amount will be charged after last installment .</h5>
			<h5>4: All installments will be consider paid only when it will be debited in Din Properties’s bank account.</h5>
		</div>
		<div class="container">
      <div className="row my-3">
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="btn btn-primary FiveMarlaPPBtn m-1">
					<Link to="/SevenNew" className='Link'>7 Marla Payment Plan</Link>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="btn btn-primary FiveMarlaPPBtn m-1">
					<Link to="/TenNew" className='Link'>10 Marla Payment Plan</Link>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
				<div class="btn btn-primary FiveMarlaPPBtn m-1">
					<Link to="/Kanal" className='Link'>1 Kanal Payment Plan</Link>
				</div>
			</div>
      </div>
		</div>
	</div>
    
    </>
  )
}

export default SevenNew