import React,{useState} from 'react'
import Images from '../Componentz/EventImages'
import Ccarousel from "../Componentz/Ccarousel"
import Modal from 'react-modal';
import Dealers from '../Componentz/Dealers';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

const Residential = () => {
	const navigate=useNavigate()
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
	const[Modalopen,setModal]=useState(false)
	const {Five,Seven,Ten,Kanal,Modalimages}=Images
	console.log(Modalimages)
  return (
    <>
	<Helmet>
		<title>Residential Plots for Sale in Chiniot - Din Gardens</title>
		<meta name='description' content="Residential plots for sale in chiniot, if you want to buy residential plots for investment or for a living then you can visit Din Gardens housing society. 5 Marla, 7 Marla, 10 Marla, and 1 Kanal residential plots are available for sale."/>
		<meta property='og:title'content='Residential Plots for Sale in Chiniot - Din Gardens'/>
		<meta property='og:description' content="Residential plots for sale in chiniot, if you want to buy residential plots for investment or for a living then you can visit Din Gardens housing society. 5 Marla, 7 Marla, 10 Marla, and 1 Kanal residential plots are available for sale."/>
	</Helmet>
		{/* <div class="contain ">
		<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669986197/Dinproperties/Final_Resi_uedlpj.jpg" alt="Residential plots in din gardens chiniot" title="residemtial-plan" className=' w-100'/>
	</div> */}


	<div className='container'>
	<div>
	<h1>Residential Plots for Sale in Chiniot by Din Properties</h1>
				<p style={{ textAlign:"justify" }}>Many of us dream of buying land, property, or a house. The affluent housing society Din Gardens Properties continues its reign and offers top-notch residential plots for sale in Chiniot. Apart from its upscale lifestyle and secure environment, the exceptional facilities at Din Gardens Properties have made it a top-tier neighborhood to work and live in. Therefore, purchasing residential plots in Din Gardens Housing Society could be an excellent investment in terms of ROI. On top of that, the borough is known for its ultramodern infrastructure combined, dedicated commercial areas, and pristinely landscaped green spaces.</p>
	
	</div>
		<div className="row mx-auto">
<div className="col-md-12 text-center mx-auto">
<img className='mx-auto my-2 residentialimg' src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391427/dinproperties2023/residential_wouatd.webp" alt="din properties residential payment plan" />
	
	</div>			
</div>
<div className='row'>
			<div className="col-md-12">
				{/* <h1>Residential Plots for Sale in Chiniot by Din Properties</h1>
				<p style={{ textAlign:"justify" }}>Many of us dream of buying land, property, or a house. The affluent housing society Din Gardens Properties continues its reign and offers top-notch residential plots for sale in Chiniot. Apart from its upscale lifestyle and secure environment, the exceptional facilities at Din Gardens Properties have made it a top-tier neighborhood to work and live in. Therefore, purchasing residential plots in Din Gardens Housing Society could be an excellent investment in terms of ROI. On top of that, the borough is known for its ultramodern infrastructure combined, dedicated commercial areas, and pristinely landscaped green spaces.</p>
				 */}
				<h2>5 Marla Plots for Sale in Chiniot</h2>
				<p style={{ textAlign:"justify" }}>Din Gardens Housing Society offers the best affordable 5 marla plots for sale in Chiniot. Our experienced professionals deal honestly with our valuable customers and try their best to change the customer's point of view regarding real estate advisory from dismissive to optimistic. <a onClick={()=>{navigate("5marla-payment-plan")}}> 5 Merla Payment Plan</a> </p>
				
				<h2>10 Marla Plots for Sale in Chiniot</h2>
				<p style={{ textAlign:"justify" }}>Din Gardens Housing Society offers the best affordable 10 marla plots for sale in Chiniot. The beautifully landscaped gated community is credited with introducing the concept of a sustainable lifestyle and eco-friendly development in the region. <Link to={"10marla-payment-plan"}><h6>10 Marla Payment Plan</h6></Link></p>
				<h2>1 Kanal Plots for Sale in Chiniot</h2>
				<p style={{ textAlign:"justify" }}>Din Gardens Housing Society offers the best affordable 1 Kanal plots for sale in Chiniot. From stunning vertical developments to sprawling gated communities, this borough's serene environment and lush green surroundings have made it an ideal location to build a dream home in Chiniot. <Link to={"1kanal-payment-plan"}><h6> 1 Kanal Payment Plan</h6></Link></p>
				
			</div>
			 </div>

	</div>
    
				{/* <div className="container my-1 border-bottom MC4" >
				<h4>RESIDENTIAL ELEVATION</h4>
					<div className='row'>
					<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
						<Ccarousel Data={Five} style={{margin:"0"}}/>
					</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<h1 style={{textAlign: "center", color: "#165359"}}>5 MARLA</h1>
							<div className="row row-cols-1 mb-3">
								<div className="col justify-content-center text-center">
									<button style={{backgroundColor:" #165359", color: "white", padding: "5px 15px", textAlign: "center", fontSize: "12px",cursor: "pointer"}} data-bs-toggle="modal" data-bs-target="#Fivemid">5 Marla Mediterranean</button>
									<button  className='ms-2 mt-2' style={{backgroundColor: "#921a1f", color: "white", padding: "5px 15px", textAlign: "center",fontSize: "12px", cursor: "pointer",}} data-bs-toggle="modal" data-bs-target="#Fivemod">5 Marla Modern</button>
								</div>
								<div className="col">
								</div>
							</div>

							
						</div>
						</div>
  
  
					


			
		</div>
				<div className="container mt-3 border-bottom" >
					<div className='row '>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<h1 style={{textAlign: "center", color: "#165359"}}>7 MARLA</h1>
								

							<div className="row row-cols-1 mb-1 ">
								<div className="col justify-content-center text-center">
									<button style={{backgroundColor:" #165359", color: "white", padding: "5px 15px", textAlign: "center", fontSize: "12px",cursor: "pointer"}} data-bs-toggle="modal" data-bs-target="#Sevenmid">7 Marla Mediterranean</button>
									<button  className='ms-2 mt-2' style={{backgroundColor: "#921a1f", color: "white", padding: "5px 15px", textAlign: "center",fontSize: "12px", cursor: "pointer",}} data-bs-toggle="modal" data-bs-target="#Sevenmod">7 Marla Modern</button>
								</div>
								<div className="col">
								</div>
							</div>
							
						</div>
					<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
						<Ccarousel Data={Seven}/>
					</div>
						</div>
  
  

			
		</div>
				<div className="container my-1 border-bottom" >
					<div className='row'>
					<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
						<Ccarousel Data={Ten}/>
					</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<h1 style={{textAlign: "center", color: "#165359"}}>10 MARLA</h1>
							<div className="row row-cols-1 mb-3">
								<div className="col justify-content-center text-center">
									<button style={{backgroundColor:" #165359", color: "white", padding: "5px 15px", textAlign: "center", fontSize: "12px",cursor: "pointer"}} data-bs-toggle="modal" data-bs-target="#Tenmid">10 Marla Mediterranean</button>
									<button  className='ms-2 mt-2' style={{backgroundColor: "#921a1f", color: "white", padding: "5px 15px", textAlign: "center",fontSize: "12px", cursor: "pointer",}} data-bs-toggle="modal" data-bs-target="#Tenmod">10 Marla Modern</button>
								</div>
								<div className="col">
								</div>
							</div>	
							
						</div>
						</div>
  
  

			
		</div>
				<div className="container mt-3 border-bottom" >
					<div className='row'>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<h1 style={{textAlign: "center", color: "#165359"}}>1 Kanal</h1>
							<div className="row row-cols-1 mb-1">
								<div className="col justify-content-center text-center">
									<button style={{backgroundColor:" #165359", color: "white", padding: "5px 15px", textAlign: "center", fontSize: "12px",cursor: "pointer"}} data-bs-toggle="modal" data-bs-target="#Kanalmid">1 kanal Mediterranean</button>
									<button  className='ms-2 mt-2' style={{backgroundColor: "#921a1f", color: "white", padding: "5px 15px", textAlign: "center",fontSize: "12px", cursor: "pointer",}} data-bs-toggle="modal" data-bs-target="#Kanalmod">1 kanal Modern</button>
								</div>
								<div className="col">
								</div>
							</div>
								
							
						</div>
					<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
						<Ccarousel Data={Kanal}/>
					</div>
						</div>
  
  

			
		</div>
		
		{
			Modalimages.map((val)=>{
				return(
				
<div class="modal fade" id={val.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" style={{height:"450px" ,}}>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{val.title}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" >
		
<div className="row m-auto">
<img src={val.img} title={val.id}  alt={val.id} className='m-auto' />
	
	</div>				
				
      </div>
      
    </div>
  </div>
</div>)
				
			})
		} */}

	

	
		
		
		
    </>
  )
}

export default Residential