import React,{useState,useEffect} from 'react'
import  emailjs  from 'emailjs-com'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CContactUS from './CContactus';
import { Helmet } from 'react-helmet';


const ContactUS = () => {
 

const [firstname, setFirstname] = useState("")
const [lastname, setLastname] = useState("")
const [email, setEmail] = useState("")
const [mobile, setMobile] = useState("")
const [company, setCompany] = useState("")
const [Subject, setSubject] = useState("")
const [Message, setMessage] = useState("")
const contactusApi = (e) => {
        e.preventDefault();
    axios.post(`https://contactapi.softvalley.com.pk/api/Contactus`,
        {
            "Firstname": firstname,
            "Lastname": lastname,
            "Email": email,
            "Mobile": mobile,
            "Company": company,
            "Subject": Subject,
            "Message": Message
        })
        .then((respon) => {
            
            if (respon.data.Message == "Success") {
                
                
                toast.success(' Message has been sent succeccfully', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
               
                setFirstname("")

                setEmail("")
                setMobile("")
               
                setSubject("")
                setMessage("")

            }
            else {
                toast.error("something create prpblem please wait !", {
                    position: toast.POSITION.TOP_CENTER
                  });
                
            }
        }).catch((er) => {
            console.log('er', er)
        })
}


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

 

    // const sendMail=(e)=>{
        
    //     emailjs.sendForm('service_b09d0w7','template_hl1ow94',e.target,'qmsDpMytQodoIYGDF').then(res=>{console.log(res)}).catch(err=>{console.log(err)})
    // }
  return (
    <>
    <Helmet>
<title>Contact Us by Email, Call or Come in to Our Office - Din Gardens Chiniot
</title>
<meta property='og:title' content='Contact Us by Email, Call or Come in to Our Office - Din Gardens Chiniot'/>
<meta property='og:description'content='Contact Us - Din Gardens housing society Chiniot, get in touch with us through email, call, and social media platform OR come into our office.' />
<meta name='description'content='Contact Us - Din Gardens housing society Chiniot, get in touch with us through email, call, and social media platform OR come into our office.' />
    </Helmet>
    <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
    <div className="container-fluid " id="ContactUs">
            <div className="row">
                <div className="col-md-6 col-md-offset-3">
                <CContactUS/>
              
            </div>
            </div>
            <div className="map-container" style={{marginTop:"20px" }}>
		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3394.9231909951695!2d72.99743881463533!3d31.69066764588499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39223c7bdc102aed%3A0xff228a997a6d35c0!2sDin%20Gardens!5e0!3m2!1sen!2s!4v1632261782664!5m2!1sen!2s" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
    </div>
        </div>
    </>
  )
}

export default ContactUS