import React from 'react'
import Slider from '../Componentz/Slider'
import Ccarousel from '../Componentz/Ccarousel'
import Images from '../Componentz/EventImages'
import Eventsheader from "../Componentz/images/Events-header.jpg"
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'


const Events = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
	const{Image1,Image2,Image3,Image4,Image5}=Images
	
  return (
    <>
	<Helmet>
<title>Events - Din Gardens Chiniot</title>
<meta name='description' content='All events of Din Gardens Chiniot - Din Gardens organized Azadi events, Din Family Mela, Tambola night event, stall at Expo center and inauguration of society.'/>
<meta property='og:title'content='Events - Din Gardens Chiniot'/>
<meta property='og:description' content='All events of Din Gardens Chiniot - Din Gardens organized Azadi events, Din Family Mela, Tambola night event, stall at Expo center and inauguration of society.'/>
	</Helmet>
    <div className="contain">
		<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779249/Dinproperties/Events-header_qscf1k.jpg" alt="Events in din gardens chiniot" title="event" className='w-100'/>
	</div>
    <div className="container">
		<div id="E1">
			<h1>Din Azaadi Festival</h1>
		</div>
		<div className="EIC container">
            <div className='row'>
			<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
				<div className="EventSh">
					<h1>Din Azaadi Festival</h1>
					<h4>Date – 14th August, 2018</h4>
					<div className="EventLis">
						<ol>
							<li>Din gardens arranged Azaadi festival for the festive people of Chiniot on Independence Day</li>
							<li>More than 1500 people took part and had fun at the festival.</li>
							<li>Flag raising ceremony made for a patriotic event at the ceremony.</li>
							<li>Main attractions included rides, various food stalls, magic shows and different game competitions for kids and their parents.</li>
							<li>National songs and different activities made it a memorable one for attendees.</li>
							<li>Reminding the attendees of the importance to plant trees, a Tree plantation ceremony was held to promote it.</li>
						</ol>
					</div>
				</div>
			</div>
			<div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
				{/* <Slider Data={Data} /> */}
				<Ccarousel Data={Image1}/>
			</div>
            </div>
		</div>
	</div>
	<div id="E1">
			<h1>Din Gardens at Expo</h1>
		</div>
	<div className="EIC container">
		<div className='row'>
			<div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
				<Ccarousel Data={Image3}/>
			</div>
			<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
				<div className="EventSh">
					<h1>Din Gardens Stall at Expo</h1>
					<h4>Date – 14th August, 2018</h4>
					<div className="EventLis">
						<ol>
							<li>Being the one of the big sponsors at Lahore expo on 11<sup>th</sup> August, 2018 by Superior College was indeed an honor for Din Gardens.</li>
							<li>Main goal of our stall was to instigate a sense of supportive environment for businesses as well as to promote entrepreneurship.</li>
							<li>Company’s authorized dealers and main representatives also participated in the Expo by displaying their company at a very prominent and huge stall.</li>
							<li>Din Gardens project information was placed on all the promotions of the Expo.</li>
						</ol>
					</div>
				</div>
			</div>
			</div>	
		</div>

		<div className="container">
		<div id="E1">
			<h1>Tambola Night with Din Gardens</h1>
		</div>
		<div className="EIC container">
			<div className="row">
			<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
				<div className="EventSh">
					<h1>Tambola Night with Din Gardens</h1>
					<h4>Date – 14th August, 2018</h4>
					<div className="EventLis">
						<ol>
							<li>Din gardens organized Tambola night at Chenab Club</li>
							<li>Main purpose of the event was to promote sale of the society’s Plots.</li>
							<li>Authorized Dealers also got a chance to meet and talk about project with potential clients.</li>
							<li>Tambola game was the main attraction for the guests as well as live music and dinner.
							</li>
						</ol>
					</div>
				</div>
			</div>
			<div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
				
			
				<Ccarousel Data={Image2}/>
		  		</div>
				  </div>
			</div>
			<div className="container">
		<div id="E1">
			<h1>Din Family Mela</h1>
		</div>
		<div className="EIC container">
			<div className="row">
			<div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
				<Ccarousel Data={Image4}/>
			</div>
			<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
				<div className="EventSh">
					<h1>Din Family Mela</h1>
					<h4>Date – 27th & 28th April-2018</h4>
					<div className="EventLis">
						<ol>
							<li>Din gardens organized a grand family mela within the society premises.</li>
							<li>Families from the entire city took part at the event with lots of activities and rides.</li>
							<li>People of Chiniot and Faisalabad enjoyed such a mega event for this first time in Chiniot where more than 15000 people took part.</li>
							<li>Main attractions included food stalls, magic show, fire show and fireworks.</li>
							<li>Famous singers Gohar Mumtaz, Dhol Master Gonga Saen and Malko made the event great feat.</li>
						</ol>
					</div>
				</div>
			</div>
			</div>
		</div>
	</div>
		</div>
		<div className="container">
		<div id="E1">
			<h1>Inaugration of Society</h1>
		</div>
		<div className="EIC container mb-5">
			<div className="row">
			<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
				<div className="EventSh">
					<h1>Inaugration of Society</h1>
					<h4>Date – 27th April, 2018</h4>
					<div className="EventLis">
						<ol>
							<li>S.M Muneer, father of the S.M. Imran (CEO) was the Chief Guest at the inauguration ceremony.</li>
							<li>Delegates from different backgrounds like government, private entities were also present.</li>
							<li>Inauguration marked the beginning of project.</li>
							<li>Chief guest appreciated the effort of developers and impressed upon the importance of betterment of real estate.</li>
						</ol>
					</div>
				</div>
			</div>
			<div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
				<Ccarousel Data={Image5}/>
			</div>
			</div>
		</div>
	</div >
    
    </>
  )
}

export default Events