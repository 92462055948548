import AMmosque1 from "../Componentz/images/AMmosque1.jpg"
import AMmosque2 from "../Componentz/images/AMmosque2.jpg"



const Images={
    
     Image1:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779308/Dinproperties/E1-2_uawvyp.jpg",
         title:"Azadi Festival in din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779306/Dinproperties/E1-7_rpfxnm.jpg",
         title:"Azadi Festival in din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779305/Dinproperties/E1-6_kalxfn.jpg",
         title:"Azadi Festival in din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779305/Dinproperties/E1-5_se3l9o.jpg",
         title:"Azadi Festival in din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779305/Dinproperties/E1-1_rbnaex.jpg",
         title:"Azadi Festival in din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779304/Dinproperties/E1-3_ueivzp.jpg",
         title:"Azadi Festival in din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779304/Dinproperties/E1-4_qejxiv.jpg",
         title:"Azadi Festival in din gardens chiniot"
    },
    ],
     Image2:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779607/Dinproperties/E3-15_o85wbd.jpg",
     title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779606/Dinproperties/E3-14_khw9t9.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779605/Dinproperties/E3-6_becwdb.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779605/Dinproperties/E3-13_qi5pri.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779604/Dinproperties/E3-10_zbrmua.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779604/Dinproperties/E3-12_cpcle4.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779604/Dinproperties/E3-11_z21yf3.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779603/Dinproperties/E3-4_kaffkv.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779602/Dinproperties/E3-7_oq4dmj.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779602/Dinproperties/E3-8_upum36.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779602/Dinproperties/E3-5_yktyfm.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779602/Dinproperties/E3-9_vnzbet.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779602/Dinproperties/E3-8_upum36.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779600/Dinproperties/E3-3_dweudw.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779599/Dinproperties/E3-1_dbqc6f.jpg",
         title:"Tambola night with din gardens"
            },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779599/Dinproperties/E3-2_kdr4zu.jpg",
         title:"Tambola night with din gardens"
            },
    
    ],
    Image3:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779493/Dinproperties/E2-1_rg0xz7.jpg",
         title:"Din gardens stall at expo center lahore"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779496/Dinproperties/E2-5_rdtuvn.jpg",
         title:"Din gardens stall at expo center lahore"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779493/Dinproperties/E2-6_stsuvi.jpg",
         title:"Din gardens stall at expo center lahore"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779492/Dinproperties/E2-4_cu34u6.jpg",
         title:"Din gardens stall at expo center lahore"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779491/Dinproperties/E2-2_ymzjqe.jpg",
         title:"Din gardens stall at expo center lahore"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779491/Dinproperties/E2-3_q6fl5e.jpg",
         title:"Din gardens stall at expo center lahore"
    },
    
    ],
    Image4:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779818/Dinproperties/E4-6_q2qqli.jpg",
         title:"Din gardens family mela"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779818/Dinproperties/E4-1_og0drq.jpg",
         title:"Din gardens family mela"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779818/Dinproperties/E4-2_obpe04.jpg",
         title:"Din gardens family mela"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779819/Dinproperties/E4-5_y40g8m.jpg",
         title:"Din gardens family mela"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779819/Dinproperties/E4-4_xczywa.jpg",
         title:"Din gardens family mela"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779821/Dinproperties/E4-8_c1icej.jpg",
         title:"Din gardens family mela"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779821/Dinproperties/E4-7_jew7cz.jpg",
         title:"Din gardens family mela"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779822/Dinproperties/E4-9_xtqojd.jpg",
         title:"Din gardens family mela"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779822/Dinproperties/E4-3_opk1pt.jpg",
         title:"Din gardens family mela"
    },
    
    ],
    Image5:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779984/Dinproperties/E5-7_orlllc.jpg",
         title:"Inauguration of society din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779982/Dinproperties/E5-6_x07qwq.jpg",
         title:"Inauguration of society din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779986/Dinproperties/E5-8_hnzhj0.jpg",
         title:"Inauguration of society din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779980/Dinproperties/E5-1_ptvvmc.jpg",
         title:"Inauguration of society din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779979/Dinproperties/E5-2_dytbyc.jpg",
         title:"Inauguration of society din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779979/Dinproperties/E5-5_bl7vuh.jpg",
         title:"Inauguration of society din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779979/Dinproperties/E5-3_q7kw8a.jpg",
         title:"Inauguration of society din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668779975/Dinproperties/E5-4_iubgj7.jpg",
         title:"Inauguration of society din gardens chiniot"
    },

    
    ],
    Mosque:[
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1669214055/Dinproperties/mosque2_gdmh1x.jpg",
            title:"Mosque in din gardens chiniot"
        },
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1669214054/Dinproperties/mosque_xj2rkd.jpg",
            title:"Mosque in din gardens chiniot"
        }
    ],
    Community:[
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772557/Dinproperties/Community_szlp5v.jpg",
            title:"Community center in din gardens chiniot"
        },
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772636/Dinproperties/gym_hm76dl.jpg",
            title:"Gym in din gardens chiniot"
        },
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772662/Dinproperties/swimmingpool_iqsh2v.jpg",
            title:"Swimming pool in din gardens chiniot"
        },
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772699/Dinproperties/squash_drp0ai.jpg",
            title:"Squash in din gardens chiniot"
        }
    ],
    School:[
        {
            img: "https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776146/Dinproperties/Amschool_xniy9y.jpg",
            title:"School building on din gardens chiniot"

        },
        {
            img: "https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776146/Dinproperties/Amschool_xniy9y.jpg",
            title:"School building on din gardens chiniot"

        }
    ],
    Hospital:[
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776358/Dinproperties/AMhospital_mrer8g.jpg",
            title:"hospital"
        },
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776358/Dinproperties/AMhospital_mrer8g.jpg",
                title:"hospital1"
        }
    ],
    Market:[
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776416/Dinproperties/AMmarket_ejjwyp.jpg",
            title:"market"
        },
        {
            img:"ht",
            title:"market"
        }
    ],
    Park:[
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1669993442/Dinproperties/Park02_br6d49.jpg",
            title:"Park in din gardens chiniot",
        },
        {
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1669993444/Dinproperties/Park03_nmqcts.jpg",
            title:"Park in din gardens chiniot",
        },
        {
            title:"Park in din gardens chiniot",
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1669993444/Dinproperties/Park05_pwbmjq.jpg"
        },
        {
            title:"Park in din gardens chiniot",
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1669993450/Dinproperties/Park04_lxltqe.jpg"
        },
        {
            title:"Park in din gardens chiniot",
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1669993450/Dinproperties/Park01_zbu17o.jpg"
        }
        ,
        {
            title:"Park in din gardens chiniot",
            img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1669993451/Dinproperties/Park06_lotbcv.jpg"
        }
    ],
    Five:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772333/Dinproperties/mid5_xqssra.jpg",
    id:"Fivemid",
title:"5 marla residential plots in din gardens chiniot"},
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772369/Dinproperties/mod5_gxihbn.jpg",
    id:"Fivemod",
title:"5 marla residential plots in din gardens chiniot"},
    ],
    Seven:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772247/Dinproperties/mid7_rea7pv.jpg",
    id:"Sevenmid",
title:"7 marla residential plots in din gardens chiniot"},
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772285/Dinproperties/mod7_wxqici.jpg",
        id:"Sevenmod",
        title:"7 marla residential plots in din gardens chiniot"
    },
    ],
    Ten:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772160/Dinproperties/mid10_ir21nd.jpg",
        id:"Tenmid",title:"10 marla residential plots in din gardens chiniot"
    },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772207/Dinproperties/mod10_p3v9hv.jpg",
        id:"Tenmod",title:"10 marla residential plots in din gardens chiniot"},
    ],
    Kanal:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772089/Dinproperties/mid1_gr2p33.jpg",
        id:"Kanalmid",title:"1 kanal residential plots in din gardens chiniot"},
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772120/Dinproperties/mod1_zdyfux.jpg",
        id:"Kanalmod",title:"1 kanal residential plots in din gardens chiniot"},
    ],
    Modalimages:[
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772333/Dinproperties/mid5_xqssra.jpg",
        id:"Fivemid",
    title:"5 Marla Mediterranean"},
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772369/Dinproperties/mod5_gxihbn.jpg",
        id:"Fivemod",
        title:"5 Marla Modern"},
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772247/Dinproperties/mid7_rea7pv.jpg",
        id:"Sevenmid",
        title:"7 Marla Mediterranean"},
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772285/Dinproperties/mod7_wxqici.jpg",
        title:"7 Marla Modern",
        id:"Sevenmod" },
        {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772160/Dinproperties/mid10_ir21nd.jpg",
        id:"Tenmid",
        title:"10 Marla Mediterranean"
    },
    {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772207/Dinproperties/mod10_p3v9hv.jpg",
    id:"Tenmod",
    title:"10 Marla Modern"},
    {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772089/Dinproperties/mid1_gr2p33.jpg",
    id:"Kanalmid",
    title:"1 Kanal Mediterranean"},
    {img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772120/Dinproperties/mod1_zdyfux.jpg",
    id:"Kanalmod",
    title:"1 Kanal Modern"},
    
]
}
export default Images 