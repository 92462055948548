import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai';

const News = () => {
    const Newss=()=>{
        document.getElementById("NewsCard").style.display="block";
        document.getElementById("newsBtn").style.display="none";

    }
    const Closeme=()=>{
        
        document.getElementById("NewsCard").style.display="none";
        document.getElementById("newsBtn").style.display="block ";
    }
  return (
    <>
    <div id='NewsBtn'>

    <p className='' onClick={()=>Newss()} id="newsBtn">&nbsp;PRESS RLEASE&nbsp;</p>
    </div>
    <div  id="icon_wrapp">
  
    <div id='NewsCard'>
        <div className="card-header" style={{background:"#921a1f",borderRadius:"5px"}} ><h5 className='text-light text-end me-2'>Press Release <AiFillCloseCircle className='ms-5' onClick={()=>Closeme()}/></h5></div>

        <marquee  direction="up" height="300" speed="100"scrolldelay="200" className="text-center">
            <h5 className="card-title ps-3" style={{lineHeight:"10px"}}>Dodgem Car (Coming Soon)</h5><hr/> 
           <a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672233535/dinproperties2023/dodgem-cars_wudrkr.jpg"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672233535/dinproperties2023/dodgem-cars_wudrkr.jpg" className='mb-1 ' style={{width:"280px"}}/> </a>
<p className=' news-p px-3' >Are you ready for a fun ride? Dodgem car is going to launch soon at DIN GARDENS CHINIOT ! Our newest attraction is perfect for anyone looking for quality experience of ride. We've designed our dodgem cars with advanced safety features, so you can enjoy an adrenaline-filled ride without any worry.<br/></p>
<h5 className="card-title ps-3" style={{lineHeight:"10px"}}>Din Gardens Chiniot</h5><hr/> 
           <a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg" className='mb-1 ' style={{width:"280px"}}/> </a>
<p className=' news-p px-3' >A golden investment opportunity for you. The new identity of Chiniot city (Din Gardens) offers a secure, modern and reliable residential project, located at an ideal location on Faisalabad Sargodha road. It provides a modern lifestyle with all the amenities and facilities. This project is designed to meet all the needs of the residents and provide them a lavish and comfortable living experience.

The project features several recreational amenities such as parks, hospitals, mosques, community clubs, etc.<br/> 

Limited 5 marlas, 10 marla & 1 Kanal residential plots, and 4 Marla commercial plots with easy installments are available. Booking starts from 20%.

Call 042 111 111 346 for more details & info.<br/></p>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391612/dinproperties2023/din-properties1_auaygp.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391612/dinproperties2023/din-properties1_auaygp.webp" className=' mb-1' style={{width:"280px"}}/></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391624/dinproperties2023/din-properties2_gvanbr.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391624/dinproperties2023/din-properties2_gvanbr.webp" className=' mb-1' style={{width:"280px"}}/></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391622/dinproperties2023/din-properties3_t0gyyc.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391622/dinproperties2023/din-properties3_t0gyyc.webp" className=' mb-1' style={{width:"280px"}}/></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391616/dinproperties2023/din-properties4_pfwkw1.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391616/dinproperties2023/din-properties4_pfwkw1.webp" className=' mb-1' style={{width:"280px"}}/></a>
<hr className='Divider mx-auto'/>
            <h5 className="card-title ps-3" style={{lineHeight:"10px"}}>Din Gardens Chiniot</h5><hr/> 
           <a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg" className='mb-1 ' style={{width:"280px"}}/> </a>
<p className=' news-p px-3' >A golden investment opportunity for you. The new identity of Chiniot city (Din Gardens) offers a secure, modern and reliable residential project, located at an ideal location on Faisalabad Sargodha road. It provides a modern lifestyle with all the amenities and facilities. This project is designed to meet all the needs of the residents and provide them a lavish and comfortable living experience.

The project features several recreational amenities such as parks, hospitals, mosques, community clubs, etc.<br/> 

Limited 5 marlas, 10 marla & 1 Kanal residential plots, and 4 Marla commercial plots with easy installments are available. Booking starts from 20%.

Call 042 111 111 346 for more details & info.<br/></p>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391612/dinproperties2023/din-properties1_auaygp.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391612/dinproperties2023/din-properties1_auaygp.webp" className=' mb-1' style={{width:"280px"}}/></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391624/dinproperties2023/din-properties2_gvanbr.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391624/dinproperties2023/din-properties2_gvanbr.webp" className=' mb-1' style={{width:"280px"}}/></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391622/dinproperties2023/din-properties3_t0gyyc.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391622/dinproperties2023/din-properties3_t0gyyc.webp" className=' mb-1' style={{width:"280px"}}/></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391616/dinproperties2023/din-properties4_pfwkw1.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391616/dinproperties2023/din-properties4_pfwkw1.webp" className=' mb-1' style={{width:"280px"}}/></a>
<hr className='Divider mx-auto'/>

</marquee>
    </div>





</div>
    </>
  )
}

export default News