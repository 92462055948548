import React from 'react'
import"../Componentz/Style.css"
import { IoMdCall  } from 'react-icons/io';

import rising from "../Componentz/images/rising.jpg"
import tahir from "../Componentz/images/tahir.jpg"
import {AiOutlineMail} from 'react-icons/ai'
import { Link } from 'react-router-dom';


const Dealers = () => {
  return (
    <>
    <div id="agents">
			<h5>MEET OUR AUTHORIZED DEALERS</h5>
		</div>
    <div className='container'>
      <div className='row px-2 mx-5 '>
        <div className='col-md-6 justify-content-center'>
          <div className='card border-0 mx-auto mb-5' id="ContCard">
            <div className='card-body mb-3'>
                <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772928/Dinproperties/rising_snvaoj.jpg" alt="rising" title="rising"/>
               </div>
               <h4>Rising Estates</h4>
               <a href="tel:+92333-65 899 11" className='ViewProfile'> <IoMdCall size='16px'/>:+92333-65 899 11</a>
               <Link to="/contactus" className='ViewProfilee'><AiOutlineMail size='16px'/>:info@dinproperties.com.pk</Link>
          </div>
        </div>
        <div className='col-md-6 '>
          <div className='card border-0 mx-auto mb-5' id="ContCard">
            <div className='card-body mb-3'>
                <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772957/Dinproperties/tahir_od761l.jpg" alt="tahir" title="tahir"/>
               </div>
               <h4>Tahir Maan’s & Company</h4>
               <a href="tel:+92300-71 220 00"className='ViewProfile' ><IoMdCall size='16px'/>:+92300-71 220 00</a>
               <Link to="/contactus" className='ViewProfilee'><AiOutlineMail size='16px'/>:info@dinproperties.com.pk</Link>
          </div>
        </div>
        
      </div>
    </div>

    </>
  )
}

export default Dealers