import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import "../Componentz/Style.css"
import slider1 from "../Componentz/images/slider1.jpg"
import slider2 from "../Componentz/images/slider2.jpg"
import slider3 from "../Componentz/images/slider3.jpg"
import slider4 from "../Componentz/images/slider4.jpg"
import slider6 from "../Componentz/images/slider6.jpg"


const Slider = () => {
    return (
        <>
            <Carousel indicators={false}  >
                <Carousel.Item>
                   <div className='container'>
                   <div className='row '>
                        <div className='col-md-6 py-1 '>
                        <img
                        className="d-block "
                        src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671709872/dinproperties2023/5-Marla-Medi_rl5ml7.webp"
                        alt="5 marla residential plot in din gardens chiniot"
                    />
						<div class="bottom-left ">5 Marla Mediterranean</div>

                        </div>
                        <div className='col-md-6'>
                        <img
                        className="d-block "
                        
                        src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671709872/dinproperties2023/5-Marla-Modern_ds151d.webp"
                        alt="5 marla residential plot in din gardens chiniot "
                    />
						<div class="bottom-left ">5 Marla Modern</div>
                    
                        </div>
                    </div>
                   </div>
                    
                   
                </Carousel.Item>
                <Carousel.Item>
                <div className='container'>
                   <div className='row '>
                        <div className='col-md-6 py-1'>
                        <img
                        className="d-block "
                        src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671710443/dinproperties2023/1-kanal-Medi_mwiaba.webp"
                        alt="1 kanal residential plot in din gardens chiniot "
                    />
						<div class="bottom-lef">1 Kanal Mediterranean</div>

                        </div>
                        <div className='col-md-6 '>
                        <img
                        className="d-block "
                        
                        src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671710440/dinproperties2023/1-Kanal-Modern_xr7rpu.webp"
                        alt="1 kanal residential plot in din gardens chiniot"
                    />
						<div class="bottom-left">1 Kanal Modern</div>

                        </div>  
                    </div>
                   </div>

                    
                </Carousel.Item>
                <Carousel.Item>
                <div className='container'>
                   <div className='row '>
                        <div className='col-md-6 py-1'>
                        <img
                        className="d-block"
                        src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671710105/dinproperties2023/10-Marla-Medi_ypjno8.webp"
                        alt="10 marla residential plot in din gardens chiniot"
                    />
						<div class="bottom-left ms-2">10 Marla Mediterranean</div>

                        </div>
                        <div className='col-md-6'>
                        <img
                        className="d-block "
                        
                        src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671710105/dinproperties2023/10-Marla-Modern_jqno3k.webp"
                        alt="10 marla residential plot in din gardens chiniot"
                    />
						<div class="bottom-left ms-2 ">10 Marla Modern</div>

                        </div>
                    </div>
                   </div>

                    
                </Carousel.Item>
            </Carousel>
        </>
    );
}
export default Slider;