import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Dealers from '../../Componentz/Dealers'


const ComTwo = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
     <div class="container">
		<div class="FiveMarlaPP">
			<p style={{color: "#921a1f"}}>70 Lacs Commercial 2.5 Year</p>
			<p style={{color: "#921a1f"}}>(MONTHLY PAYMENT PLAN)</p>
		</div>
		<div class="container">
      <div className="row">
			
			<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
			
				<div class="FMFTable">
					<p>&nbsp;</p>
					<table class="FiveMarlaPPTable2 text-center m-auto" align="center">
						<tbody>
							
                            <tr id="FiveMarlaTr1">
								<th>Months</th>
								<th>4 Marla</th>
							</tr>
							<tr>
								<td>Down Payment</td>
								<td className='p-2'>20%(1400,000)</td>
							</tr>
							<tr>
								<td>10/06/2020</td>
								<td className='p-2'>0</td>
							</tr>
							<tr>
								<td>10/07/2020</td>
								<td className='p-2'>0</td>
							</tr>
							<tr>
								<td>10/08/2020</td>
								<td className='p-2'>0</td>
							</tr>
							<tr>
								<td>10/09/2020</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/10/2020</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/11/2020</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/12/2020</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/01/20201</td>
								<td className='p-2'>15% (1500,000)</td>
							</tr>
							<tr>
								<td>10/02/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/03/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/04/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/05/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/06/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/07/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/08/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/09/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/10/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/11/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
								</tr>
							<tr>
								<td>10/12/2021</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/01/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/02/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/03/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/04/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/05/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/06/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/07/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/08/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/09/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/10/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/11/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/12/2022</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/01/2023</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>10/02/2023</td>
								<td className='p-2'>1.8%(126,000)</td>
							</tr>
							<tr>
								<td>Ballot Amount</td>
								<td className='p-2'>11% (770,000)</td>
							</tr>

                            <tr>
								<td style={{color: "#fff", backgroundColor: "#921a1f"}}>Possession </td>
								<td className='p-2'>15%(105,0000)</td>
							</tr>
							<tr>
								<td style={{color: "#fff", backgroundColor: "#921a1f"}}>TOTAL</td>
								<td className='p-2'>7000,000/-</td>
							</tr>
                           
						</tbody>
					</table>
					<p>&nbsp;</p>
				</div>
			</div>
      </div>
		</div>
		<div class="FiveMarlaPPNotes">
			<h1 style={{fontSize:"22px"}}>Note:</h1>
			<h5>1: Din Gardens will Charge 0.05% per day of total amount due as plenty if customer pays installment after due date which is 10th of every month. Furthermore the file is liable to cancel if thestomer fails to pay installment within 75 days from due date. Cancellation policy will apply.</h5>
			<h5>2: Ballot Amount will be due when balloting is announced by society.</h5>
			<h5>3: Possession Amount will be charged after last installment .</h5>
			<h5>4: All installments will be consider paid only when it will be debited in Din Properties’s bank account.</h5>
		</div>
		<div class="container">
      <div className="row my-3">
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="btn btn-primary FiveMarlaPPBtn m-1">
					<Link to="/SevenNew" className='Link'>7 Marla Payment Plan</Link>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="btn btn-primary FiveMarlaPPBtn m-1">
					<Link to="/TenNew" className='Link'>10 Marla Payment Plan</Link>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
				<div class="btn btn-primary FiveMarlaPPBtn m-1">
					<Link to="/Kanal" className='Link'>1 Kanal Payment Plan</Link>
				</div>
			</div>
      </div>
		</div>
	</div>
    
    </>
  )
}

export default ComTwo