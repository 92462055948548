import React,{useEffect,useState} from 'react'
import "../Componentz/Style.css"
import { Link } from 'react-router-dom'
import logo from "../Componentz/images/logo.png"
import News from './News'
import {AiOutlineClose} from 'react-icons/ai'

const Navbar = () => {
  // const [openmoda,SetmodalOpe]=useState(false);


    const CLICKED=()=>{
      document.getElementById("NavBtn").click();
    }
   
    useEffect(() => {
      window.scrollTo(0, 0)
    //   let visited = localStorage["alreadyVisited"];
    //   if(visited) {
      
    //     document.getElementById("modale").click();
      
    //      //do not view Popup
    // } else {
    //      //this is the first time
    //      localStorage["alreadyVisited"] = true;
    //      SetmodalOpe(true);
    // }
      
    }, [])

  return (
    <>
    <div className="container-fluid">
        
        <nav className="navbar navbar-expand-lg ">
  <div className="container-fluid text-uppercase">
    <Link className="navbar-brand" to="/"><img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671707284/dinproperties2023/dinpropertieslogo_th8kyk.webp" alt='logo' style={{width:130, height:80}} /></Link>
    <button className="navbar-toggler" id="NavBtn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 ps-2 mx-auto">
      <li className="nav-item ps-1">
    <Link className="nav-link "id="nav-Link" aria-current="page" to="/" onClick={()=>CLICKED()} >Home</Link>
  </li>
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" >About us</a>
    <ul className="dropdown-menu" aria-labelledby="navbarDropdown" id="dropdown">
            <li><Link className="dropdown-item" to="/about-us" onClick={()=>CLICKED()} >About us</Link></li>
            <li><Link className="dropdown-item" to="/ceo" onClick={()=>CLICKED()} >Ceo Message</Link></li>
            
            
          </ul>
  </li>
  <li className="nav-item">
    <Link className="nav-link" to="/by-law"onClick={()=>CLICKED()}  >By Law</Link>
  </li>
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" >Properties</a>
    <ul className="dropdown-menu" aria-labelledby="navbarDropdown" id="dropdown">
            <li><Link className="dropdown-item" to="/residential" onClick={()=>CLICKED()} >Residential</Link></li>
            <li><Link className="dropdown-item" to="/commercial" onClick={()=>CLICKED()} >Commercial</Link></li>
            
            
          </ul>
  </li>
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle position-relative" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" >Payment Plan <span className='position-absolute top-0 start-80 translate-middle badge rounded-pill bg-danger'>New</span></a>
    <ul className="dropdown-menu" aria-labelledby="navbarDropdown" id="dropdown">
      <li className='dropdown-item'style={{color:"#921A1F",borderBottom:"1px solid #adb5bd"}}>New Payment Plans</li>
            <li><Link className="dropdown-item text-dark" to="/5marla-payment-plan"onClick={()=>CLICKED()}  >5 Marla Payment Plan  </Link>
            {/* <ul className="dropdown-menu submenu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="/fivePayment"  >5 Marla Payment Plan</Link></li>
            <li><Link className="dropdown-item" to="/fiveMid">5 Marla (30 Lac P/M)</Link></li>
            
            
          </ul> */}
            </li>
            {/* <li><a className="dropdown-item" href="#">7 Marla Payment Plan</a>
            <ul className="dropdown-menu submenu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="/sevenOld"  >7 Marla Old Payment Plan</Link></li>
            <li><Link className="dropdown-item" to="/sevenNew">7 Marla New Payment Plan</Link></li>
            
            
          </ul>
            </li> */}
            <li><Link className="dropdown-item text-dark" to="/10marla-payment-plan" onClick={()=>CLICKED()} >10 Marla Payment Plan</Link>
            {/* <ul className="dropdown-menu submenu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="/tenOld"  >10 Marla Old Payment Plan</Link></li>
            <li><Link className="dropdown-item" to="/tenNew">10 Marla New Payment Plan</Link></li>
            <li><Link className="dropdown-item" to="/tenThree">10 Marla Payment Plan (5 Lac)</Link></li>
            <li><Link className="dropdown-item" to="/tenFour">10 Marla Payment Plan (5.50 Lac)</Link></li>
            <li><Link className="dropdown-item" to="/tenFive">10 Marla Payment Plan (70 Lac)</Link></li>
            
            
          </ul> */}
          </li>
            <li>
              <Link className="dropdown-item text-dark" to="/1kanal-payment-plan" onClick={()=>CLICKED()} >1 kannal Payment Plan</Link>
              {/* <ul className="dropdown-menu submenu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="/kanal"  >1 Kanal Payment Plan</Link></li>
            <li><Link className="dropdown-item" to="/kanalNew">1 Kanal Payment Plan(1 crore)</Link></li>
            
            
          </ul> */}
          </li>
          <li>
              <Link className="dropdown-item text-dark" to="/commercial-payment-plan" onClick={()=>CLICKED()} >Commercial Payment Plan</Link>
              {/* <ul className="dropdown-menu submenu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="/kanal"  >1 Kanal Payment Plan</Link></li>
            <li><Link className="dropdown-item" to="/kanalNew">1 Kanal Payment Plan(1 crore)</Link></li>
            
            
          </ul> */}
          </li>
            {/* <li><a className="dropdown-item" href="#">Commercial Payment Plan</a><ul className="dropdown-menu submenu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="/com1"  >60 Lacs Payment Plan</Link></li>
            <li><Link className="dropdown-item" to="/com2">70 Lacs Payment Plan</Link></li>
            <li><Link className="dropdown-item" to="/com3">75 Lacs Payment Plan</Link></li>
            <li><Link className="dropdown-item" to="/com4">100 Lacs Payment Plan</Link></li>
            
            
          </ul></li> */}
            
            
            
          </ul>
  </li>
  
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" >Amenties</a>
    <ul className="dropdown-menu" aria-labelledby="navbarDropdown" id="dropdown">
            <li><Link className="dropdown-item" to="/mosque" onClick={()=>CLICKED()} >Mosque</Link></li>
            <li><Link className="dropdown-item" to="/community-center" onClick={()=>CLICKED()} >Community Center</Link></li>
            <li><Link className="dropdown-item" to="/school" onClick={()=>CLICKED()} >School</Link></li>
            <li><Link className="dropdown-item" to="/parks" onClick={()=>CLICKED()} >Parks</Link></li>
            <li><Link className="dropdown-item" to="/hospital" onClick={()=>CLICKED()} >Hospital</Link></li>
            <li><Link className="dropdown-item" to="/supermarkets" onClick={()=>CLICKED()} >SuperMarkets</Link></li>
            
            
          </ul>
  </li>
 
 
  <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" >Media</a>
    <ul className="dropdown-menu" aria-labelledby="navbarDropdown" id="dropdown">
            <li><Link className="dropdown-item" to="/tvc" onClick={()=>CLICKED()} >TVC</Link></li>
            <li><Link className="dropdown-item" to="/events" onClick={()=>CLICKED()} >Events</Link></li> 
          </ul>
  </li>
   <li className="nav-item">
    <Link className="nav-link" to="/blog" onClick={()=>CLICKED()} >Blog</Link>
  </li> 
  <li className="nav-item">
    <Link className="nav-link" to="/contact-us" onClick={()=>CLICKED()} >Contact</Link>
  </li>
  <li className="nav-item">
    <Link className="nav-link" to="/press-release" onClick={()=>CLICKED()} >Press Release</Link>
  </li>
        
      </ul>
      <i className="bi bi-list mobile-nav-toggle"></i>
      
    </div>
  </div>
</nav>
       
    </div>
    {/* <button type="button" id="modale" style={{display:"none"}} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>  */}

 {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content text-center" id="modalcontent" >
      <div class="modal-body position-relative">
      <a type="button" class="btn-close  btn-close-white text-danger position-absolute end-0 top-0" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></a>
        <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391409/dinproperties2023/din-properties-website_hjhadx.webp" className='webopen '/>
      </div>
      
    </div>
  </div>
</div> */}

    
    </>
    
  )
}

export default Navbar