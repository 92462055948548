import React from 'react'
import eat from "../Componentz/images/eat.png"
import lifestyle from "../Componentz/images/lifestyle.png"
import security from "../Componentz/images/security.png"
import funtime from "../Componentz/images/fun-time.png"

const Ameneties = () => {
  return (
	<>
	<div id="features">
			<h5>FEATURES</h5>
		</div>
	<div className="container">
	<div className="row mt-2 p-3">
			
			
			<div className="over col-lg-3 col-md-6 col-sm-6 col-xs-12">
				<div className="module-item">
					<div className="service-block">
						<div className="block-icon">
							<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671719583/dinproperties2023/shop_f1hfn9.webp" alt="Shopping center in din gardens chiniot" title="din-foods-eat"/>
						</div>
						<div className="block-content">
							<h2 style={{fontSize:"20px",fontFamily:"Arial, Helvetica, sans-serif",textAlign:"center"}}><strong>Shop, Dine, and Enjoy </strong></h2>
							<p className='text-xl-dark'>Enjoy safe, easy, and enjoyable shopping and dine with your loved ones at mega leisure and shopping centers. </p>
						</div>
					</div>
				</div>
			</div>
			<div className="over col-lg-3 col-md-6 col-sm-6 col-xs-12">
				<div className="module-item">
					<div className="service-block">
						<div className="block-icon">
							<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671720276/dinproperties2023/funtime_ywn3kr.webp" alt="Fun time in din gardens chiniot" title="din-funtime"/>
						</div>
						<div className="block-content">
							<h5><b>Appealing Amusement </b></h5>
							<p>Have unlimited fun at Din Gardens theme park, filled with various attractions, including food spots, electric rides, exciting games, and more. 
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="over col-lg-3 col-md-6 col-sm-6 col-xs-12">
				<div className="module-item">
					<div className="service-block">
						<div className="block-icon">
							<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671720276/dinproperties2023/lifestyle_hjuio3.webp" alt="Lifestyle in din gardens chiniot" title="din-lifestyle"/>
						</div>
						<div className="block-content">
							<h5 ><b>Luxury Lifestyle </b></h5>
							<p >Leverage advantage from our development plans formulated following the most advanced guidelines and enjoy a luxury lifestyle.</p>
						</div>
					</div>
				</div>
			</div>
			<div className="over col-lg-3 col-md-6 col-sm-6 col-xs-12">
				<div className="module-item">
					<div className="service-block">
						<div className="block-icon">
							<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671720276/dinproperties2023/security_uczsb8.webp" alt="24/7 security in din gardens chiniot" title="din-security"/>
						</div>
						<div className="block-content">
							<h5><b>Round-The-Clock Security</b></h5>
							<p>Feel safe within vigilant security patrols, guarded entrance gates, and reliable boundary walls securing the entire society. </p>
						</div>
					</div>
				</div>
			</div>

		</div>
		</div>
	</>
  )
}

export default Ameneties