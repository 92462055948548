import React from 'react'
import Data from './AboutCard'

const Aboutcar = () => {
  return (
    <>
    <div className='row mx-1  mt-5'>
            {
                Data.map((val)=>{
                    return<>
                    <div className='col-md-4 p-1 mx-auto'>
                <div className='card w-100' >
                    <img src={val.img}className='card-img h-100' title={val.title} alt={val.title} />
                    <div className='intro text-center '>
                        <h4 className='introName mt-3'>{val.title}</h4>
                        <p className='introText text-dark'>{val.text}</p>
                    </div>
                </div>
            </div>
                    </>
                })
            }
            
            
        </div>
    </>
  )
}

export default Aboutcar