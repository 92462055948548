import React from 'react'
import {AiOutlineZoomIn,AiOutlineZoomOut} from "react-icons/ai"
import {TbZoomOutArea} from "react-icons/tb"
import   Big from "../Componentz/images/map9.jpg"


import CContactUS from '../Pages/CContactus'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";




const PlanLayout = () => {

    
  return (
    <>
    <div className="container mt-5">
		<div id="features">
			<h5>LAYOUT PLAN</h5>
		</div>
        <div className='row'>
		<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center ">
			<div class="col-sm-12 col-xs-12 text-center">
		<iframe
		id="Mapz"
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3394.9231909951695!2d72.99743881463533!3d31.69066764588499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39223c7bdc102aed%3A0xff228a997a6d35c0!2sDin%20Gardens!5e0!3m2!1sen!2s!4v1632261782664!5m2!1sen!2s"
			 height="450"  allowfullscreen="" loading="lazy" className='map me-1 pe-1'></iframe>
	</div>
		</div>
		<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
			<div className="layout-section2 mt-4">
				<h2 className="">Where All Roads Pave The Way To Home! </h2>
				<p className="">Close to the Chiniot’s vicinity and far from the madding masses, the supreme location of Din Gardens is the best of both worlds. Become a part of this chiniot housing society and benefit from seclusion and serenity along with amenities and sophistication on an exclusive lifestyle within the suburbia of a gated community. </p>
				<div className="embed">
					<iframe
						src="https://www.youtube.com/embed/d_bmLycU-6k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
				</div>
			</div>
		</div>
        </div>
	</div>
	<div className='row mt-1 mx-auto'>

    <div class="container   col-lg-12 col-md-12 col-sm-12 col-xs-12">
<div className=' container'>
<TransformWrapper

      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
			<div className="position-realtive">
           <div className="tools position-absolute  end-25">
              <button className='btn btn-default btn-primary m-1 text-right' onClick={() => zoomIn()}><AiOutlineZoomIn  style={{width:"25px",height:"25px"}}/></button>
              <button className='btn btn-default btn-primary m-1' onClick={() => zoomOut()}><AiOutlineZoomOut style={{width:"25px",height:"25px"}}/></button>
              <button className='btn btn-default btn-danger	 m-1' onClick={() => resetTransform()}><TbZoomOutArea  style={{width:"25px",height:"25px"}}/>	</button>
            </div>
			</div>
            <TransformComponent>
				

<img id="la-img" src={Big} alt="plan"title="plan"/>
					
              
              
            </TransformComponent>
			
			
          </React.Fragment>
        )}
		 

      </TransformWrapper>

	
	</div>		
	</div>
    <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
	
                <CContactUS/>
        
			
	</div>
	</div>
    </>
  )
}

export default PlanLayout