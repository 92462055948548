import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {BsCheckLg} from "react-icons/bs"
import { Link } from 'react-router-dom'
import Amschool from "../Componentz/images/Amschool.jpg"



const School = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
	<Helmet>
		<title>School in Din Gardens Chiniot</title>
<meta name='description'content='School in Din Gardens Chiniot, Din Gardens provides International Standard Education System through schools which have fully equipped computer labs, Science Laboratories, Libraries and competent qualified teachers within your reach.' />
<meta property='og:title' content='School in Din Gardens Chiniot'/>
<meta property='og:description'content='School in Din Gardens Chiniot, Din Gardens provides International Standard Education System through schools which have fully equipped computer labs, Science Laboratories, Libraries and competent qualified teachers within your reach.' />
	</Helmet>
    <div className="container">
		<div className="row py-2">
		<div className="col-lg-7 col-md-7 col-sm-12">
			<div className="anim-img">
				<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776146/Dinproperties/Amschool_xniy9y.jpg" alt="school" title="school"/>
			</div>
		</div>
		
		<div className="col-lg-5 col-md-5 col-sm-8 col-xs-12 text-center">
			
  			<div id="description" className="collapse.show in">
  				
  				<h6 id="AMh6" style={{color:"#921A1F",fontSize:"20px"}}>Easy-to-Access Study</h6>
        		<p id="AMcolapsp" className='text-justify'>Considering the significance of quality education, especially in this modern, tech-oriented, and competitive world, Din Gardens Housing Society aims to provide   International Standard Education System through schools that would have entirely equipped science labs, computer labs, libraries, and qualified teachers within your reach in forthcoming years.</p>
 			</div>
			 <p className=" bg-warning featuresbtn mx-auto">FEATURES
 			</p>
 			<div id="features" className="">
 				<hr/>
  				<ul className="ulcolapse">
<div className="row">
  					<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Activity Room</a></li>
        			</div>
					<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Cafeteria</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Libraries</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Art Room</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>First Aid Centre</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Sports Facilities</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Auditorium</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Furnished Labs</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>WiFi</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Books and Uniforms Store</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Innovation Studio</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Window Coverings</a></li>
        			</div>
                    </div>
        		</ul>
        	</div>
 		</div>	
		 </div>	
	
	</div> 
    </>
  )
}

export default School