import React from 'react'
import Welcome from '../Componentz/Welcome'

import Slider from "../Componentz/Slider"

import SecSlider from '../Componentz/SecSlider';
import { Head } from 'react-static'
import slider1 from "../Components/Images/slider1.jpg"
import slider2 from "../Components/Images/slider2.jpg"
import slider3 from "../Components/Images/slider3.jpg"
import Ameneties from '../Componentz/Ameneties';
import PlanLayout from '../Componentz/PlanLayout';
import Dealers from '../Componentz/Dealers';
import Cards from '../Componentz/Cards';
import { useEffect } from 'react';
import { MetaTags } from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import News from '../Componentz/News';

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const Data = {
    Slider1: "https://res.cloudinary.com/dfsabcjvk/image/upload/v1671623981/dinproperties2023/slider1_w80b84.webp",
    Slider2: "https://res.cloudinary.com/dfsabcjvk/image/upload/v1671623981/dinproperties2023/slider2_musmos.webp",

    indicator: false
  }
  return (
    <>
      <Head>
        <meta property="og:title" content="Best Housing Society in Chiniot - Din Gardens" />
        <title>Best Housing Society in Chiniot - Din Gardens
        </title>
        <meta property='og:description' content="Din Gardens is the best housing society in Chiniot for residents looking for a luxury lifestyle." />
        <meta name='description' content="Din Gardens is the best housing society in Chiniot for residents looking for a luxury lifestyle." />
      </Head>
      
      <Slider {...Data} />
      <Welcome />
      <SecSlider />
      <Cards />
      <Ameneties />
      <PlanLayout />


    </>
  )
}

export default MainPage