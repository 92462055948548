import React from 'react'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Ccarousel = (props) => {
    const {Data}=props
  return (
    <>
    <Carousel autoPlay={true} infiniteLoop={true} interval={3000} >
        {
            Data.map((val)=>{
                return <>
                <div>
                    <img src={val.img} title={val.title} alt={val.title} className="Ccarousel" />
                    
                </div>
                </>
            })
        }
                
                
               
                </Carousel>
    
    </>
  )
}

export default Ccarousel