import React from 'react'
import Dealers from '../Componentz/Dealers'
import commercialplan from "../Componentz/images/commercial-plan.jpg"
import c1 from "../Componentz/images/c1.jpg"
import c2 from "../Componentz/images/c2.jpg"
import c3 from "../Componentz/images/c3.jpg"
import c4 from "../Componentz/images/c4.jpg"
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'


const Commercial = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
	<Helmet>
		<title>Commercial Plots for Sale in Chiniot - Din Gardens</title>
		<meta property='og:type' content='Commercial Plots for Sale in Chiniot - Din Gardens'/>
		<meta property='og:description' content='Buy commercial plots for sale in chiniot.  Are you looking for commercial plots for investment or for your own business? Then you can visit Din Gardens housing society. 2 marla and 4 marla commercial plots available for sale in chiniot.'/>
		<meta name='description' content='Buy commercial plots for sale in chiniot.  Are you looking for commercial plots for investment or for your own business? Then you can visit Din Gardens housing society. 2 marla and 4 marla commercial plots available for sale in chiniot.'/>
	</Helmet>
    {/* <div className="contain">
		<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669912272/Dinproperties/Commercial_Elevation_33_o0d4o6.jpg" alt="Commercial elevation in din gardens chiniot" title="commercial-plan" className='w-100'/>
	</div> */}
	<div className='container'>
	<div className='row'>
			<div className="col-md-12">
				<h1>Commercial Plots for Sale in Chiniot by Din Properties</h1>
				<p style={{ textAlign:"justify" }}>Din Gardens housing society offers commercial plots for sale that have the potential to give suitable returns on the investment. The commercial area, envisioned to become a hub of commercial activities in the future, is equipped with all necessary facilities and can provide a strong base for businesses and other commercial activities. The area possesses all the basic facilities and amenities ranging from parks, hospitals, security, and other essential utilities that are prerequisites for starting a commercial activity. The commercial property in chiniot hosts significant brands, and it is said that Din Gardens will become a central hub of commercial activities in Chiniot.</p>
			<Link to={"commercial-payment-plan"}><a>Commercial Payment Plan</a></Link>
			</div>
			 </div>
		<div className="row">
<div className="col-md-12 text-center">
<img className='mx-auto my-2 residentialimg' src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391420/dinproperties2023/commercial_ao1orx.webp" alt="din properties Commercial payment plan" />
	
	</div>			
</div>


</div>	
    {/* <div className="container MC4" >
		<h4>COMMERCIAL ELEVATION</h4>
	 	<div className="ic4 container" >
            <div className='row'>
	 		<div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
	 			<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669894750/Dinproperties/BTW_heba3u.jpg" alt="Commercial area in din gardens chiniot"title="Commercial area in din gardens chiniot" className='com_img'/>
	 		</div>
	 		<div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
	 			<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669216744/Dinproperties/commercialview_irxery.jpg" alt="Commercial area in din gardens chiniot"title="Commercial area in din gardens chiniot" className='com_img'/>
	 		</div>
	 		<div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
	 			<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669216371/Dinproperties/wecafe_dkmpk1.jpg" alt="Commercial area in din gardens chiniot"title="Commercial area in din gardens chiniot" className='com_img'/>
	 		</div>
	 		<div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
	 			<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668778037/Dinproperties/c1_jximog.jpg" alt="Commercial area in din gardens chiniot"title="Commercial area in din gardens chiniot" className='com_img'/>
	 		</div>
             </div>
	 	</div>
		<div className="bc4 container mb-5">
            <div className="row text-center">
			<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
				<Link className=" btn w-100 my-4" to="/fivepayment" id="b4" style={{backgroundColor: "#921a1f",color: "white"}}>
					Payment Plan
				</Link>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
				<Link className=" btn w-100 my-4" to="/amaneties" id="b4" style={{backgroundColor: "#921a1f",color: "white"}}>
					Amenities
				</Link>
                </div>
			</div>
		</div>
		<div  className="bb4 container-fluid mb-5">
            <div className="row">
                <div className="col-md-6">

				<Link to="/Com1"><button id="b4" style={{backgroundColor: "#165359", color: "white"}} className="w-100">Payment Plan</button></Link>
				</div>
                <div className="col-md-6"><Link to="/Dealers"><button id="b4" style={{backgroundColor: "#d4af37", color: "white"}} className="w-100">Dealers</button></Link>
				</div>
                <div className="col-md-6"><Link to="/dinproperties.com.pk"><button id="b4" style={{backgroundColor: "#921a1f",color: "white"}} className="w-100">Back to Home</button></Link>
				</div>
                <div className="col-md-6"><Link to="/Amaneties"><button id="b4" style={{backgroundColor: "#000000", color: "#d4af37"}} className="w-100">Amenities</button></Link>
                </div></div>
		</div>
	</div> */}

    </>
  )
}

export default Commercial