import React from 'react'
import { useEffect } from 'react'
import Dealerz from"../Componentz/Dealers"
import dealers from "../Componentz/images/Dealers.jpg"



const Dealers = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
        <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668778266/Dinproperties/Dealers-header_sjssdq.jpg" alt="dealers" title="din-dealers" className='w-100'/>

       <Dealerz/>
    </>
  )
}

export default Dealers