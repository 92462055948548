import React from 'react'
import s0 from "../Componentz/images/s0.jpg"
import s1 from "../Componentz/images/s1.jpg"
import Slider from '../Componentz/Slider'

const Welcome = () => {
    const Data={
        Slider1:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1672383999/dinproperties2023/din-properties_voc7xp.webp",
        Slider2:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1671707284/dinproperties2023/dinpropertieslogo_th8kyk.webp",
        control:false,
        indicator:false,
    }
  return (
    <>
    <div className="container mt-5 mb-4">
		<div className='row'>
        <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 text-justify" style={{textAlign:"justify"}}>
			<h2 style={{fontSize:"22px",color:"#921a1f"}}>WELCOME TO DIN GARDENS</h2>
			<p className='welcome'>The latest venture of Din Industries, Din Properties, aims to provide high-quality real estate to our valuable customers. With years of top-notch services, Din Properties has become a trustworthy name. </p>
			<p className='welcome'>This chiniot housing society is committed to innovation, high-quality, and satisfactory customer services that develop transparent relationships and empower respect with our potential clients. We ensure that you won’t regret investing in Din Properties. So join us and enjoy luxury living at affordable prices. </p>
		</div>
		<div className="col-lg-4 col-md-4 col-sm-5 col-xs-12">
			<div className="container">
            <Slider {...Data}/>   
			</div>
		</div>
        </div>
	</div>
    </>
  )
}

export default Welcome