import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {BsCheckLg} from "react-icons/bs"
import { Link } from 'react-router-dom'
import hospital from "../Componentz/images/AMhospital.jpg"


const Hospital = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
	<Helmet>
		<title>Hospital in Din Gardens Chiniot</title>
<meta property="og:title" content='Hospital in Din Gardens Chiniot'/>
<meta property="og:description" content="Din Gardens hospital will provide world class Name health facilities and trained staff for healthcare along with modernized latest equipment that meets your basic and tertiary health needs. Here health care is a few steps away."/>
<meta name="description" content="Din Gardens hospital will provide world class Name health facilities and trained staff for healthcare along with modernized latest equipment that meets your basic and tertiary health needs. Here health care is a few steps away."/>
	</Helmet>
    <div className="container">
		<div className="row py-2">
		

		<div className=" col-lg-7 col-md-7 col-sm-12 mt-1">
			<div className="anim-img">
				<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776358/Dinproperties/AMhospital_mrer8g.jpg" alt="hospital" title="din-hospital"/>
			</div>
		</div>
		
		<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-center">
        
			
		<div id="description" className="collapse.show in mb-3" >
		
			<h6 id="AMh6" style={{color:"#921A1F",fontSize:"20px"}}>Health For all</h6>
		  <p id="AMcolapsp">
Din Gardens Housing Society aims to provide top-tier health facilities, trained healthcare staff, and modernized equipment that meets your tertiary and primary health requirements. Health care would be just a few steps away at Din Gardens Housing Society in the approaching years.
.</p>
	   <p className=" featuresbtn  border-0 text-center mx-auto" >FEATURES
	   </p>
	   <div id="features" className="collapse.show in text-center">
		   <hr/>
			<ul className="ulcolapse">
			  <div className="row">
				<div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>Dental facility</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>Outdoor Shower</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>Washing Area</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>ECG Services</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>Pharmacy</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>WiFi</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>Laboratory services</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>TV Cable</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>Window Coverings</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>Lawn Area</a></li>
			  </div>
			  <div className="col-sm-4">
				  <li><a href=""><BsCheckLg/>Ward/ Indoor facility.</a></li>
			  </div>
			  </div>
		  </ul>
	  </div>
	  </div>
   </div>

		
		</div>	

		{/* <div className="col-sm-12">
			<h3>Hospital <button id="GotoAmB" className='ms-3 mt-2'><Link id="AMSB"to="/Amaneties" title="">AMENITIES</Link></button></h3>
	    </div> */}
				
		
	</div>
    </>
  )
}

export default Hospital