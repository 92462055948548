import React from 'react'

const Press = () => {
  return (
    <>
    <div className="container-fluid pressbody ">
        <div className="row pt-3">

        <div class="card mb-3 mx-auto" id="presscard" >
        <h1 className=' pt-2' style={{color:"#921a1f"}}> Press Release</h1>
  <div class="row ">
    <div class="col-md-6 text-center presslogo  ">
    <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671707284/dinproperties2023/dinpropertieslogo_th8kyk.webp"  className='w-75 text-center mx-auto' />
      
    </div>
    <div class="col-md-6">
      <div class="card-body text-center mx-auto px-2  mt-0 mb-0 bg-light">
      <marquee  direction="up" height="400" scrolldelay="200" className="text-center">
      <h5 className="card-title ps-3" style={{lineHeight:"10px"}}>Dodgem Car (Coming Soon)</h5><hr/> 
           <a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672233535/dinproperties2023/dodgem-cars_wudrkr.jpg" className='text-center mx-auto'> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672233535/dinproperties2023/dodgem-cars_wudrkr.jpg" className='w-75 text-center mx-auto'/> </a>
<p className=' news-p px-3' >Are you ready for a fun ride? Dodgem car is going to launch soon at DIN GARDENS CHINIOT ! Our newest attraction is perfect for anyone looking for quality experience of ride. We've designed our dodgem cars with advanced safety features, so you can enjoy an adrenaline-filled ride without any worry.<br/></p><br/>
            <h5 className="card-title ps-3" style={{lineHeight:"10px"}}>Din Gardens Chiniot</h5><hr/> 
           <a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg" className='text-center mx-auto'> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg " className='w-75 text-center mx-auto'/> </a>
<p className=' news-p px-3' >A golden investment opportunity for you. The new identity of Chiniot city (Din Gardens) offers a secure, modern and reliable residential project, located at an ideal location on Faisalabad Sargodha road. It provides a modern lifestyle with all the amenities and facilities. This project is designed to meet all the needs of the residents and provide them a lavish and comfortable living experience.

The project features several recreational amenities such as parks, hospitals, mosques, community clubs, etc.<br/> 

Limited 5 marlas, 10 marla & 1 Kanal residential plots, and 4 Marla commercial plots with easy installments are available. Booking starts from 20%.

Call 042 111 111 346 for more details & info.<br/></p>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391612/dinproperties2023/din-properties1_auaygp.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391612/dinproperties2023/din-properties1_auaygp.webp" className=' mb-1 PRimgs' /></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391624/dinproperties2023/din-properties2_gvanbr.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391624/dinproperties2023/din-properties2_gvanbr.webp" className=' mb-1 PRimgs' /></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391622/dinproperties2023/din-properties3_t0gyyc.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391622/dinproperties2023/din-properties3_t0gyyc.webp" className=' mb-1 PRimgs' /></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391616/dinproperties2023/din-properties4_pfwkw1.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391616/dinproperties2023/din-properties4_pfwkw1.webp" className=' mb-1 PRimgs' /></a>
<hr className='Divider mx-auto'/>
      <h5 className="card-title ps-3" style={{lineHeight:"10px"}}>Dodgem Car (Coming Soon)</h5><hr/> 
           <a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672233535/dinproperties2023/dodgem-cars_wudrkr.jpg" className='text-center mx-auto'> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672233535/dinproperties2023/dodgem-cars_wudrkr.jpg" className='w-75 text-center mx-auto'/> </a>
<p className=' news-p px-3' >Are you ready for a fun ride? Dodgem car is going to launch soon at DIN GARDENS CHINIOT ! Our newest attraction is perfect for anyone looking for quality experience of ride. We've designed our dodgem cars with advanced safety features, so you can enjoy an adrenaline-filled ride without any worry.<br/></p><br/>
            <h5 className="card-title ps-3" style={{lineHeight:"10px"}}>Din Gardens Chiniot</h5><hr/> 
           <a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg" className='text-center mx-auto'> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg " className='w-75 text-center mx-auto'/> </a>
<p className=' news-p px-3' >A golden investment opportunity for you. The new identity of Chiniot city (Din Gardens) offers a secure, modern and reliable residential project, located at an ideal location on Faisalabad Sargodha road. It provides a modern lifestyle with all the amenities and facilities. This project is designed to meet all the needs of the residents and provide them a lavish and comfortable living experience.

The project features several recreational amenities such as parks, hospitals, mosques, community clubs, etc.<br/> 

Limited 5 marlas, 10 marla & 1 Kanal residential plots, and 4 Marla commercial plots with easy installments are available. Booking starts from 20%.

Call 042 111 111 346 for more details & info.<br/></p>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391612/dinproperties2023/din-properties1_auaygp.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391612/dinproperties2023/din-properties1_auaygp.webp" className=' mb-1 PRimgs' /></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391624/dinproperties2023/din-properties2_gvanbr.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391624/dinproperties2023/din-properties2_gvanbr.webp" className=' mb-1 PRimgs' /></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391622/dinproperties2023/din-properties3_t0gyyc.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391622/dinproperties2023/din-properties3_t0gyyc.webp" className=' mb-1 PRimgs' /></a>
< a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391616/dinproperties2023/din-properties4_pfwkw1.webp"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1672391616/dinproperties2023/din-properties4_pfwkw1.webp" className=' mb-1 PRimgs' /></a>
<hr className='Divider mx-auto'/>
           

</marquee>
      </div>
    </div>
  </div>
</div>
        </div>
        
        
        
        
        <div>

    




  
    


        </div>
        
    {/* <div className="Press m-2 my-5 w-75 mx-auto ">
        <h1 className='text-center pt-2 ' style={{color:"#921a1f",borderBottom:"1px solid gray"}}> Press Release</h1>
        <div className="row mt-5">
            <div className="col-md-6  pressimg">
                <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668772006/Dinproperties/s0_ogud2c.jpg"  className='w-75 ' style={{borderRight:"1px solid gray"}}/>
            </div>
            <div className="col-md-6">
            <marquee  direction="up" height="320">
            <h5 className="card-title ps-3" style={{lineHeight:"10px"}}>i loved in gardens</h5><hr/> 
           <a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg" className='w-75 '/> </a>
<p className=' news-p px-3' >A golden investment opportunity for you. The new identity of Chiniot city (Din Gardens) offers a secure, modern and reliable residential project, located at an ideal location on Faisalabad Sargodha road. It provides a modern lifestyle with all the amenities and facilities. This project is designed to meet all the needs of the residents and provide them a lavish and comfortable living experience.

The project features several recreational amenities such as parks, hospitals, mosques, community clubs, etc.<br/> 

Limited 5 marlas, 10 marla & 1 Kanal residential plots, and 4 Marla commercial plots with easy installments are available. Booking starts from 20%.

Call 042 111 111 346 for more details & info.<br/>

#HousingSociety #CommercialPlots #Chiniot #house #societyandculture #realestatelife #ilovedingardens #dingardens</p>
<hr className='Divider mx-auto'/>
            <h5 className="card-title ps-3" style={{lineHeight:"10px"}}>i loved in gardens</h5><hr/> 
           <a href="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg"> <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg" className='w-75 '/> </a>
<p className=' news-p px-3' >A golden investment opportunity for you. The new identity of Chiniot city (Din Gardens) offers a secure, modern and reliable residential project, located at an ideal location on Faisalabad Sargodha road. It provides a modern lifestyle with all the amenities and facilities. This project is designed to meet all the needs of the residents and provide them a lavish and comfortable living experience.

The project features several recreational amenities such as parks, hospitals, mosques, community clubs, etc.<br/> 

Limited 5 marlas, 10 marla & 1 Kanal residential plots, and 4 Marla commercial plots with easy installments are available. Booking starts from 20%.

Call 042 111 111 346 for more details & info.<br/>

#HousingSociety #CommercialPlots #Chiniot #house #societyandculture #realestatelife #ilovedingardens #dingardens</p>
<hr className='Divider mx-auto'/>

</marquee>
            </div>
        </div>
    </div> */}

    </div>
    </>
  )
}

export default Press