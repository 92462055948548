import React from 'react'
import "../Componentz/Style.css"
import Data from "../Componentz/CardData"
import { Link } from 'react-router-dom'

const Cards = () => {
  return (
    <>
    <div className='container my-5'>
    <div id="features">
			<h5 className='text-uppercase'>Come For The Accommodation, Stay For The Amenities.</h5>
		</div>
      <div className='card-group '>
      
        <div className='col-12 col-md-6 col-lg-4  p-1 '>
        <div className="card1 card bg-dark text-white border-0 w-100" id="card1">
  <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711196/dinproperties2023/mosque_dsew9e.webp" className="card-img" title="din-cafe" alt="Mosque in din gardens chiniot" id="Card-img" />
  <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711196/dinproperties2023/mosque2_afqbm1.webp" className=" img1 card-img" title="din-cafe" alt="Mosque in din gardens chiniot" id="Card-img" />
  <div className="card-img-overlay" >
  <div className='aniText'>
    <p className='card-text text-center'>An Aesthetically designed Mosque at Din Gardens depicts the true spirit of
							Islamic architecture with space for more than 2000 people.</p>
    <div className="vc_btn3 text-center"><Link to="/mosque">Learn More</Link></div>


    </div>
  </div>
</div></div>
        <div className='col-12 col-md-6 col-lg-4  p-1 '>
        <div className="card1 card bg-dark text-white border-0 w-100" id="card1">
  <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711196/dinproperties2023/commercial_bpdidp.webp" className="card-img" title="din-cafe" alt="Commercial boulevard din gardens chiniot" id="Card-img" />
  <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711197/dinproperties2023/commercials_xnjqb2.webp" className=" img1 card-img" title="din-cafe" alt="Commercial boulevard din gardens chiniot" id="Card-img" />
  <div className="card-img-overlay" >
  <div className='aniText'>
    <p className='card-text text-center'>A dedicated commercial boulevard with international food chains and renowned
							brands.</p>
    <div className="vc_btn3 text-center"><Link to="/commercial">Learn More</Link></div>
              

    </div>
  </div>
</div></div>
        <div className='col-12 col-md-6 col-lg-4  p-1 '>
        <div className="card1 card bg-dark text-white border-0 w-100" id="card1">
        <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711197/dinproperties2023/park_rz2cmx.webp" className="card-img" title="din-funpark" alt="Theme park din gardens chiniot" id="Card-img" />
  <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671890029/dinproperties2023/park2_oqasew.webp" className="img1 card-img" title="din-funpark" alt="Theme park din gardens chiniot" id="Card-img1" />
 <div className="card-img-overlay" >
  <div className='aniText'>
    <p className='card-text text-center'>A specialized theme park featuring electric rides, food spots, interesting
							activities and other entertainment outlets.</p>
    <div className="vc_btn3 text-center"><Link to="/parks">Learn More</Link></div>


    </div>
  </div>
</div></div>
        
      {
    Data.map((val)=>{
        return<>
        <div className='col-12 col-md-6 col-lg-4  p-1 '>
        <div className="card bg-dark text-white border-0 w-100">
  <img src={val.img} className="card-img" title={val.title} alt={val.alt} id="Card-img" />
  <div className="card-img-overlay">
	<div className='aniText'>
		
    <h5 className="card-title">{val.title}</h5>
    <p className="card-text text-center">{val.text}</p>
    <div className="vc_btn3 text-center"><Link to={val.to}>Learn More</Link></div>

	</div>
  </div>
</div></div>
        </>
    })
  }	
  

      </div>
	
	
</div>
    </>
  )
}

export default Cards