import React from 'react'
import Aboutcar from '../Componentz/Aboutcar'
import "../Componentz/Style.css"
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'




const Aboutus = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div>
                <Helmet>
                    <meta property="og:title" content="About Us - Din Gardens living - Residential & Commercial Plots" />
                    <title>About Us - Din Gardens living - Residential & Commercial Plots</title>
                    <meta property='og:description' content="Buy your own residential & commercial plots in the best location in Chiniot. We are providing all the lifestyle facilities under a single roof. Din Gardens housing scheme Chiniot." />
                    <meta name='description' content="Buy your own residential & commercial plots in the best location in Chiniot. We are providing all the lifestyle facilities under a single roof. Din Gardens housing scheme Chiniot." />
                </Helmet>
            </div>
            <div>
                <div className='container'>
                    <div className='row'>
                        <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1673017773/dinproperties2023/son-of-the-soil_ntizax.webp" alt="messages" title="message" />
                    </div>
                    <Aboutcar />

                    <div id="CSR">
                        <h5>Corporate Social Responsibilities</h5>
                    </div>
                </div>
                <div className='row mx-auto'>
                    <div className='col-md-4 p-1 mx-auto'>
                        <div className='card mx-auto' id="Ccard">
                            <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775792/Dinproperties/CSR_mzbria.jpg" className='card-img h-100' alt='csr' title="csr" />
                            <div className='Csr text-center mx-auto '>
                                <h4 className='introName mt-3'>CSR</h4>
                                <p className='introText text-dark'>Renovation & upgradation of a Police Station.& Construction, Renovation & Equipment Upgradation of two Major Hospitals.& Construction of an old age home for the aged homeless citizens.& Construction of a yaadgar-e-Shuhada to pay tribute to the martyrs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutus