import React,{useEffect} from 'react'
import"../Componentz/Style.css"
import { AiFillEnvironment, AiFillFacebook,AiFillInstagram,AiFillMail,AiFillTwitterSquare,AiFillYoutube,AiOutlineInstagram, AiOutlineMail} from 'react-icons/ai';
import { Link } from 'react-router-dom';


const Footer = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])

	  


  return (
    <>
    <div className="container-fluid footer-t p-5"  style={{   justifyContent:"center" }}> 
    <div className='conatiner'>
        <div className='row row-cols-8	 px-2' >
        <div className="col">
			<div className="widget-top">
				<h4>Our Pages</h4>
			</div>
			<div className="textwidget">
				<p><Link to="/about-us">About us</Link></p>
				<p><Link to="/contact-us">Contact us</Link></p>
				<p><Link to="/Amaneties">Amenities</Link></p>
				<p><Link to="/residential">Residential Plan</Link></p>
				<p><Link to="/commercial">Payment</Link></p>
				{/* <p><Link to="/dealers">Dealers</Link></p> */}
			</div>
		</div>
        <div className="col " id="props">
			<div className="widget-top">
				<h4>Properties</h4>
			</div>
			<div className="textwidget">
				<p><Link to="/5marla-payment-plan">5 Marla</Link></p>
				{/* <p><Link to="/sevenNew">7 Marla</Link></p> */}
				<p><Link to="/10marla-payment-plan">10 Marla</Link></p>
				<p><Link to="/1kanal-payment-plan">1 Kannal</Link></p>
				<p><Link to="/commercial-payment-plan">Commercial</Link></p>
				<p><Link to="/by-law">By Laws</Link></p>
			</div>
		</div>
        <div className="col " >
			<div className="widget-top" >
				<h4>Amenities</h4>
			</div>
			<div className="textwidget">
				<p><Link to="/mosque">Mosque</Link></p>
				<p><Link to="/school">School</Link></p>
				<p><Link to="/hospital">Hospital</Link></p>
				<p><Link to="/community-center">Community Center</Link></p>
				<p><Link to="/parks">Parks</Link></p>
				<p><Link to="/supermarkets">Super Market</Link></p>
			</div>
		</div>

		<div className="col">
			{/* <img src="loogo.png"/> */}
			
			<p className='widget-top'>Don’t forget to follow us on:</p>
			<ul className="social text-center mx-auto">
				<li>
					<a href="https://www.facebook.com/DinGardensPk/" className="btn-facebook">
					
                        <AiFillFacebook style={{height:"20px",width:"20px"}}/>
					</a>
				</li>
				<li>
					<a href="https://twitter.com/DinGardens" className="btn-twitter">
						<AiFillTwitterSquare style={{height:"20px",width:"20px"}}/></a>
				</li>
				<li>
					<a className="btn-youtube" href="https://www.youtube.com/channel/UCCZPhVuHxv_ADrXroYrHPKA/videos">
						<AiFillYoutube style={{height:"20px",width:"20px"}}/></a>
				</li>
				<li><a className="btn-instagram" href="https://www.instagram.com/dingardens/">
						<AiOutlineInstagram style={{height:"20px",width:"20px"}}/></a>
				</li>
			</ul>
		</div>
		

        </div>
        </div>
		
		{/* <div className="row text-center" id="fooot">
		<p><br/>Don’t forget to follow us on:</p>
			<p className='text-center text-light'>
				
					<a href="https://www.facebook.com/DinGardensPk/" className="btn-facebook">
					
                        <AiFillFacebook style={{height:"20px",width:"20px" ,color:"white"}}/>
					</a>
				
				
					<a href="https://twitter.com/DinGardens" className="btn-twitter">
						<AiFillTwitterSquare style={{height:"20px",width:"20px" ,color:"white"}}/></a>
				
					<a className="btn-youtube" href="https://www.youtube.com/channel/UCCZPhVuHxv_ADrXroYrHPKA/videos">
						<AiFillYoutube style={{height:"20px",width:"20px",color:"white"}}/></a>
				<a className="btn-instagram" href="https://www.instagram.com/dingardens/">
						<AiOutlineInstagram style={{height:"20px",width:"20px",color:"white"}}/></a>
			
		</p>
		</div> */}
		</div>


    <nav className="navbar navbar-bottom px-5">
        
		<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center  ">
			
				<p className='mt-2'>Din Properties - All rights reserved.</p>
		
		</div>
		{/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center ">
			
				
						<p className='mt-2'>
							<Link to="/privacy" style={{textDecoration:"none"}} className="text-light mx-2">privacy</Link>
							<Link to="/terms" style={{textDecoration:"none"}} className="text-light mx-2">terms & condition</Link>
							<Link to="/contactus" style={{textDecoration:"none"}} className="text-light mx-2">Contact</Link>
							
							</p>
		
			
		</div> */}
	</nav>


	<div id="icon_wrapper">
    	<a target="_blank" class="fuse_social_icons_links" href="https://www.facebook.com/DinGardensPk/">	
		<AiFillFacebook style={{height:"40px",width:"40px"}}/>
    		
    	</a><br/>
		<a target="_blank" class="fuse_social_icons_links" href="https://www.youtube.com/channel/UCCZPhVuHxv_ADrXroYrHPKA/videos">	
		<AiFillYoutube style={{height:"40px",width:"40px",color:"red"}}/>
    		
    	</a><br/>
    	<a target="_blank" class="fuse_social_icons_links" href="https://twitter.com/DinGardens">
		<AiFillTwitterSquare style={{height:"40px",width:"40px",color:"#00aced"}}/>
			
    	</a><br/>
    	
    	<a target="_blank" class="fuse_social_icons_links" href='https://www.instagram.com/dingardens/'>
		<AiFillInstagram style={{height:"40px",width:"40px" ,color:" rgb(243 4 48 / 80%)"}}/>
    		
    	</a><br/>
    	<Link target="/contactus" class="fuse_social_icons_links" to="/contactus">	
		<AiOutlineMail style={{height:"40px",width:"40px", color:"red"}}/>
    		
    	</Link>
	</div>


    </>
  )
}

export default Footer