import React from 'react'
import VideoData from '../Componentz/videodata'
import tvc from "../Componentz/images/TVC.jpg"
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'


const TVC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Helmet>
<title>Media & Information Din gardens Chiniot</title>
<meta name="description"content='Media and Information Din Gardens Chiniot - Din Gardens provides important information to its customers through media.' />
<meta property="og:description"content='Media and Information Din Gardens Chiniot - Din Gardens provides important information to its customers through media.' />
    <meta property='og:title' content='Media & Information Din gardens Chiniot'/>
    </Helmet>
    <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668770071/Dinproperties/TVC-header_gpemjc.jpg" alt="tvc" title="tvc" className='w-100' />
    <div className='container my-5'>
        <div className="row">
            {
              VideoData.map((val)=>{
                
                    return<>
                     <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">

<h6 style={{ fontSize: "17px" ,color: "#921a1f", textAlign: "left"}}>{val.title}</h6>
<p><iframe src={val.Links} style={{width: "100%",height:"220px"}}>
</iframe></p>
</div>
                    </>
                })
            }
       
        </div>
    </div>
    </>
  )
}

export default TVC