import School from "../Componentz/images/school.jpg"
import Mosque from "../Componentz/images/Mosque.jpg"
import themepark from "../Componentz/images/themepark.jpg"
import Community from "../Componentz/images/Community.jpg"
import hospital from "../Componentz/images/hospital.jpg"
import gym from "../Componentz/images/gym.jpg"
import squash from "../Componentz/images/squash.jpg"
import swimmingpool from "../Componentz/images/swimmingpool.jpg"
import boulevard2 from "../Componentz/images/Commercial-boulevard2.jpg"

const Data=[


 
    {
        title:"SCHOOL",
     text:"International Standard Education System equipped with Computer Labs, Science Laboratories, Libraries and competent qualified teachers",
     img: "https://res.cloudinary.com/dfsabcjvk/image/upload/v1671719099/dinproperties2023/school_vlrou2.webp",
     to:"/school",
    alt:"School in din gardens chiniot",

},


    {
        title:"COMMUNITY CENTER",
     text:"A state of the art community centre with modern lifestyle facilities like Gym,Sauna, Swimming Pool, Squash Courts and many more…",
    img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711198/dinproperties2023/community_ow2qp5.webp",
    to:"/community",
    alt:"Community center in din gardens chiniot"

},
    {
        title:"HOSPITAL",
     text:"World class health facilities and trained staff for healthcare just few steps away…",
     img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711196/dinproperties2023/hospital_tvoxwo.webp",
     to:"/hospital",
     alt:"Hospital in din gardens chiniot"
    

},
    {
        title:"GYM",
     text:"A dedicated Gym Area",
    img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711196/dinproperties2023/gym_snngvu.webp",
    alt:"Gym in din gardens chiniot" 
},
    {
        title:"SWIMMING POOL",
        alt:"Swimming pool in din gardens chiniot",
     text:"Save Time. Enjoy Your Pool",
    img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711197/dinproperties2023/swimmingpool_xlporn.webp",

},
    {
        title:"SQUASH",
        alt:"Squash in din gardens chiniot",
     text:"Eat, Sleep, Play Squash…",
    img:"https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711197/dinproperties2023/squash_jsc5uo.webp",

},

]
export default Data