import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {BsCheckLg} from "react-icons/bs"
import { Link } from 'react-router-dom'
import Ccarousel from "../Componentz/Ccarousel"
import Images from '../Componentz/EventImages'



const Community = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
	  const{Community}=Images
  return (
    <>
	<Helmet>
		<title>Community Center in Din Gardens Chiniot</title>
		<meta property='og:title'content='Community Center in Din Gardens Chiniot' />
		<meta property='og:description'content="Community center in Din Gardens Chiniot, The community center has facilities like sports complex, swimming pool, gym, lounge etc. Din Gardens is the best housing scheme in chiniot." />
		<meta name='description'content="Community center in Din Gardens Chiniot, The community center has facilities like sports complex, swimming pool, gym, lounge etc. Din Gardens is the best housing scheme in chiniot." />
	</Helmet>
    <div className="container">
		<div className="row py-2">
		<div className="col-lg-7 col-md-7 col-sm-12">
			<div className="anim-img">
			<Ccarousel Data={Community} />

			</div>
		</div>
		
		<div className="col-lg-5 col-md-5 col-sm-8 col-xs-12 text-center">
			
  			<div id="description" className="collapse.show in">
  				
  				<h6 id="AMh6" style={{color:"#921A1F",fontSize:"20px"}}>An Enduring Influence on All Visitors</h6>
        		<p id="AMcolapsp">Din Gardens Housing Society aims to develop an entirely functional community center that would be easy to access for all residents and outsiders. This impressive community center will offer a plethora of facilities, from the gym to the swimming pool, from the sports complex to the lounge, within the upcoming few years.</p>
 			</div>
			 <p  className=" bg-warning featuresbtn mx-auto" >FEATURES
 			</p>
 			<div id="features" className="">
 				<hr/>
  				<ul className="ulcolapse">
                    <div className="row">
  					<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Activity Room</a></li>
        			</div>
					<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Squash Court</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Weight training</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Car park</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Swimming Pool</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>WiFi</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>First Aid Centre</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Table Tennis</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Yoga aerobics</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Snooker Room</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>TV Cable</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Sports Facilities</a></li>
        			</div>
        			<div className="col-sm-4">
        				<li><a href=""><BsCheckLg/>Washroom</a></li>
        			</div>
                    </div>
        		</ul>
        	</div>
 		</div>
		 </div>		
		
	</div> 
    </>
  )
}

export default Community