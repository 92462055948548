const VideoData=[

    {
        title:"Din Properties Lifestyle Redefined",
        Links:"https://www.youtube.com/embed/4BEeKjL1umc"
        
    },
    {
        title:"Din Gardens (Happy Customers)",
        Links:"https://www.youtube.com/embed/OnaBalKFpyU"
        
    },
    {
        title:"Din Gardens (Happy Customers)",
        Links:"https://www.youtube.com/embed/xp3UgWjpRaE"
        
    },
    {
        title:"Din Gardens (Happy Customers)",
        Links:"https://www.youtube.com/embed/P6I-ffZvJ9M"
    },
    {
        title:"Son of Soil",
        Links:"https://www.youtube.com/embed/sAu3mFONm64"
    },
    {
        title:"Din Gardens Society Virtual Tour",
        Links:"https://www.youtube.com/embed/eDwnK4utU3g"
    },
    {
        title:"Din Azaadi Festival (14.08.18)",
        Links:"https://www.youtube.com/embed/VExBC-7quLM"
        
    },
    {
        title:"Din Gardens TVC for 14th Aug, 2018",
        Links:"https://www.youtube.com/embed/Njc7myuJYRM"
    },
    {
        title:"Din Family Gala Day 2 hightlights",
        Links:"https://www.youtube.com/embed/9pAE14Z3S6U"

    },
    {
        title:"Din Family Gala Day 1 Highlights",
        Links:"https://www.youtube.com/embed/iYsEJg_zCcQ"
    },
    {
        title:"Din Gardens Family Mela",
        Links:"https://www.youtube.com/embed/0eHey5-VJME"
    },
    {
        title:"Din Gardens Development TVC (Phase 1)",
        Links:"https://www.youtube.com/embed/CEM3EWTMQXA"
    },
    {
        title:"Din Gardens TVC General",
        Links:"https://www.youtube.com/embed/jZKzJLmFlrc"
        
    },
    

]

export default VideoData