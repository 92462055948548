import React from 'react'
import { Helmet } from 'react-helmet';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'
const Block = () => {
  return (
    <>
  <Helmet>
    <title>Blog</title>
    <meta name='description' content="booking"/>
  </Helmet>
    <div className="container-fluid">
        <div className="row">            
            <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671711315/dinproperties2023/map_2_k2m0ny.webp"/>
        </div>
        <div className="container">
        <div className="row mt-5">
              <div id="features">
			        <h5>Din Gardens</h5>
		</div> 
            
        <div class="card mb-3 border-0 ">
  <div class="row g-0 ">
    <div class="col-md-6 p-1">
      <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536399/Dinproperties/block_nzgskt.jpg" class="img-fluid rounded-start w-100" alt="..." style={{}}/>
    </div>
    <div class="col-md-6">
      <div class="card-body">
        <h5 class="card-title">A Higher Quality of Living.</h5>
        <p class="card-text text-justify mb-5" style={{textAlign:"justify"}}>A golden investment opportunity for you. The new identity of Chiniot city (Din Gardens) offers a secure, modern and reliable residential project, located at an ideal location on Faisalabad Sargodha road. It provides a modern lifestyle with all the amenities and facilities. This project is designed to meet all the needs of the residents and provide them a lavish and comfortable living experience.

The project features several recreational amenities such as parks, hospitals, mosques, community clubs, etc.<br/> 

Limited 5 marlas, 10 marla & 1 Kanal residential plots, and 4 Marla commercial plots with easy installments are available. Booking starts from 20%.

Call 042 111 111 346 for more details & info.</p>
        
      </div>
    </div>
  </div>
</div>
        </div>
        </div>
        {/* <div className="row mt-5">
            <div className="container">
                <div className="col-md-11 mx-auto">
                <div id="features">
			<h5>Experience the DIN`s Lifestyle</h5>
		</div> 
                    <p className='' style={{textAlign:"justify"}}>People in Chiniot prefer to live in DIN Gardens. This is due to the desire of Chinioti people to live in peaceful place that provides all amenities. But selecting a good housing scheme in Chiniot is very important to avail the best living environment. Also to reduce the chances of property frauds & scams. 

In Chiniot, people are more inclined towards investing in housing societies rather than abandoned plots. This trend is valid for short-term investments, long-term investment. Moreover for those considering to build residence in a few years. Experienced investors & buyers look for DIN Housing Society Features before investing in unlike others. There are salient reasons which attract people to invest & buy plots in DIN housing schemes.</p>
                </div>
            </div>
        </div> */}
        
        <div className="container">
        <div className="row mt-5">
              <div id="features">
			<h5>Life Style Redefined</h5>
		</div> 
            
        <div class="card mb-3 border-0">
  <div class="row g-0 ">
  <div class="col-md-6 p-1">
      <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1671536946/Dinproperties/11_nudsbn.jpg" className="img-fluid rounded-start w-100  " alt="..." style={{}}/>
    </div>
    <div class="col-md-6">
      <div class="card-body">
        <h5 class="card-title">A Higher Quality of Living.</h5>
        <p class="card-text text-justify mb-5" style={{textAlign:"justify"}}>Din Gardens is located at the prime location of Chiniot. Buy 5, 10 Marla, and 1 Kanal residential plots and 4 Marla commercial limited plots within the gated community with lots of top-notch available amenities. Book residential and commercial properties with just 20% advance and pay the remaining in easy installments.
          A golden investment opportunity for you. The new identity of Chiniot city (Din Gardens) offers a secure, modern and reliable residential project, located at an ideal location on Faisalabad Sargodha road. It provides a modern lifestyle with all the amenities and facilities. This project is designed to meet all the needs of the residents and provide them a lavish and comfortable living experience.

The project features several recreational amenities such as parks, hospitals, mosques, community clubs, etc.<br/> 

Limited 5 marlas, 10 marla & 1 Kanal residential plots, and 4 Marla commercial plots with easy installments are available. Booking starts from 20%.

Call 042 111 111 346 for more details & info.</p>
      </div>
    </div>
    
  </div>
</div>
        </div>
        </div>
        </div>
        {/* <div className="row mt-5">
            <div className="container">
                <div className="col-md-11 mx-auto">
                <div id="features">
			<h5>Experience the DIN`s Lifestyle</h5>
		</div> 
                    <p className='' style={{textAlign:"justify"}}>People in Chiniot prefer to live in DIN Gardens. This is due to the desire of Chinioti people to live in peaceful place that provides all amenities. But selecting a good housing scheme in Chiniot is very important to avail the best living environment. Also to reduce the chances of property frauds & scams. 

In Chiniot, people are more inclined towards investing in housing societies rather than abandoned plots. This trend is valid for short-term investments, long-term investment. Moreover for those considering to build residence in a few years. Experienced investors & buyers look for DIN Housing Society Features before investing in unlike others. There are salient reasons which attract people to invest & buy plots in DIN housing schemes.</p>
                </div>
            </div>
        </div> */}
        {/* <div className="row">
            <div className="container">
          <div className="col-md-11 mx-auto">
          <div className='card-group '>
      
      <div className='col-12 col-md-6 col-lg-4  p-1 '>
      <div className="card1 card bg-dark text-white border-0 w-100" id="card1">
<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669214054/Dinproperties/mosque_xj2rkd.jpg" className="card-img" title="din-cafe" alt="Mosque in din gardens chiniot" id="Card-img" />
<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669214055/Dinproperties/mosque2_gdmh1x.jpg" className=" img1 card-img" title="din-cafe" alt="Mosque in din gardens chiniot" id="Card-img" />
<div className="card-img-overlay" >
<div className='aniText'>
  <p className='card-text text-center'>An Aesthetically designed Mosque at Din Gardens depicts the true spirit of
                          Islamic architecture with space for more than 2000 people.</p>
  <div className="vc_btn3 text-center"><Link to="/mosque">Learn More</Link></div>


  </div>
</div>
</div></div>
      <div className='col-12 col-md-6 col-lg-4  p-1 '>
      <div className="card1 card bg-dark text-white border-0 w-100" id="card1">
<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669216371/Dinproperties/wecafe_dkmpk1.jpg" className="card-img" title="din-cafe" alt="Commercial boulevard din gardens chiniot" id="Card-img" />
<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669216744/Dinproperties/commercialview_irxery.jpg" className=" img1 card-img" title="din-cafe" alt="Commercial boulevard din gardens chiniot" id="Card-img" />
<div className="card-img-overlay" >
<div className='aniText'>
  <p className='card-text text-center'>A dedicated commercial boulevard with international food chains and renowned
                          brands.</p>
  <div className="vc_btn3 text-center"><Link to="/commercial">Learn More</Link></div>
            

  </div>
</div>
</div></div>
      <div className='col-12 col-md-6 col-lg-4  p-1 '>
      <div className="card1 card bg-dark text-white border-0 w-100" id="card1">
      <img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669217045/Dinproperties/funhouse_mvypuy.jpg" className="card-img" title="din-funpark" alt="Theme park din gardens chiniot" id="Card-img" />
<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1669375227/Dinproperties/Park_2_umko3d.jpg" className="img1 card-img" title="din-funpark" alt="Theme park din gardens chiniot" id="Card-img1" />
<div className="card-img-overlay" >
<div className='aniText'>
  <p className='card-text text-center'>A specialized theme park featuring electric rides, food spots, interesting
                          activities and other entertainment outlets.</p>
  <div className="vc_btn3 text-center"><Link to="/parks">Learn More</Link></div>


  </div>
</div>
</div></div>
      
	


    </div>
          </div>
            </div>
        </div> */}
  
    </>
  )
}

export default Block