import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Ccarousel from '../../Componentz/Ccarousel'
import Dealers from '../../Componentz/Dealers'
import Images from '../../Componentz/EventImages'
import School from '../School'


const Amenities = () => {
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const{Mosque,Community,School,Park,Hospital,Market}=Images
  return (
    <>
    <div class="contain">
		<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668775857/Dinproperties/Amenities-header_yxwo3r.jpg" alt="amanities" title="amenities-din"style={{width: "100%"}}/>
	</div>
    <div class="container">
		<div id="E1">
			<h1>AMENITIES</h1>
		</div>
		<div class="AMC container">
            <div className="row">
			<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<Ccarousel Data={Mosque}/>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<div class="AMPH">
					<p>&nbsp;</p>
					<h3>MOSQUE</h3>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>Gather some Blessings</p>
					<p>&nbsp;</p>
					<div class="btn btn-primary  amenities-btn">
						<Link to="/Mosque" className='Link' >Learn More</Link>
					</div>
				</div>
			</div>
            </div>
		</div>
	</div>
    <div class="container">
		<div class="AMC container">
            <div className="row">
			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<div class="AMPH">
					<p>&nbsp;</p>
					<h3>COMMUNITY CENTER</h3>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>An Impression that Lasts</p>
					<p>&nbsp;</p>
					<div class="btn btn-primary amenities-btn">
						<Link  to="/Community" className='Link'>Learn More</Link>
					</div>
				</div>
			</div>
			<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<Ccarousel Data={Community}/>
		  		</div>
                  </div>
			</div>
		</div>
        <div class="container">
		<div class="AMC container">
            <div className="row">
			<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <Ccarousel Data={School}/>
            </div>
				
			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<div class="AMPH">
					<p>&nbsp;</p>
					<h3>SCHOOL</h3>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>Study in reach</p>
					<p>&nbsp;</p>
					<div class="btn btn-primary amenities-btn" align="center">
						<Link  to="/School" className='Link' align>Learn More</Link>
					</div>
				</div>
			</div>	
            </div>
		</div>
	</div>

    <div id="features" className='mt-5'>
			<h5>What Makes Us Different</h5>
		</div>
	<div className="container">
	<div className="row mt-2 p-3">
			
			
			<div className="over col-lg-3 col-md-6 col-sm-6 col-xs-12">
				<div className="module-item">
					<div className="service-block">
						<div className="block-icon">
							<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776272/Dinproperties/AMicon3_rwecoo.png" alt="icon3" title="icons"/>
						</div>
						<div className="block-content">
							<h3><strong>SHOP.DINE.REPEAT</strong></h3>
							<p>In Din Gardens Commercial Area you can see the idea of mega shopping and leisure center
								turning into reality. Bringing you safe, easy and enjoyable shopping , where all...</p>
						</div>
					</div>
				</div>
			</div>
			<div className="over col-lg-3 col-md-6 col-sm-6 col-xs-12">
				<div className="module-item">
					<div className="service-block">
						<div className="block-icon">
							<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776323/Dinproperties/AMicon4_f5tiy2.png" alt="icon4"title="icon4"/>
						</div>
						<div className="block-content">
							<h3><b>FUN TIME</b></h3>
							<p>For all the young ones, unlimited fun awaits at theme park of Din gardens with various
								attractions, such as electric rides, food spots, various interesting games and many...
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="over col-lg-3 col-md-6 col-sm-6 col-xs-12">
				<div className="module-item">
					<div className="service-block">
						<div className="block-icon">
							<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776239/Dinproperties/AMicon2_klem8b.png" alt="icon2" title="icon2"/>
						</div>
						<div className="block-content">
							<h3><strong>LAVISH LIFESTYLE</strong></h3>
							<p>At Din Gardens, our development plans are formulated in accordance with the most advanced
								guidelines believing that basic necessities can be met in style.</p>
						</div>
					</div>
				</div>
			</div>
			<div className="over col-lg-3 col-md-6 col-sm-6 col-xs-12">
				<div className="module-item">
					<div className="service-block">
						<div className="block-icon">
							<img src="https://res.cloudinary.com/dfsabcjvk/image/upload/v1668776207/Dinproperties/AMicon1_mkv9pj.png" alt="icon1" title="icon1"/>
						</div>
						<div className="block-content">
							<h3><strong>24/7 SECURITY</strong></h3>
							<p>The visionary approach of Din Properties ensures a secure lifestyle. Guarded entrance
								gates, vigilant security patrols and a boundary wall securing the whole society
								provide...</p>
						</div>
					</div>
				</div>
			</div>

		</div>
		</div>


    <div class="container mt-5">
		<div class="AMC container">
            <div className="row">
			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<div class="AMPH">
					<p>&nbsp;</p>
					<h3>HOSPITAL</h3>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>Health For All</p>
					<p>&nbsp;</p>
					<div class="btn btn-primary amenities-btn">
						<Link  to="/Hospital" title=""className='Link' >Learn More</Link>
					</div>
				</div>
			</div>
			<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <Ccarousel Data={Hospital}/>
				
		</div></div>
	</div>
    </div>
	<div class="container">
		<div class="AMC container">
            <div className="row">
			<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <Ccarousel Data={Market}/>

			</div>	
			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<div class="AMPH">
					<p>&nbsp;</p>
					<h3>SHOPPING ARCADE</h3>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>Let's Shop</p>
					<p>&nbsp;</p>
					<div class="btn btn-primary amenities-btn">
						<Link  to="/SuperMarket" title="" className='Link'>Learn More</Link>
					</div>
				</div>
		</div>
        </div>
			
	</div>
    </div>
	<div class="container">
		<div class="AMC container">
            <div className="row">
            <div className="row">
			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<div class="AMPH">
					<p>&nbsp;</p>
					<h3>THEME PARK</h3>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>Grow old, be young</p>
					<p>&nbsp;</p>
					<div class="btn btn-primary amenities-btn">
						<Link  to="/Parks" className='Link' >Learn More</Link>
					</div>
				</div>
			</div>
			<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <Ccarousel Data={Park}/>
            </div>
		</div>
        </div>
	</div>
</div>
    </>
  )
}

export default Amenities